import styled from 'styled-components'

interface IProps {
  align?: string
  color?: string
  maxWidth?: string
}

export const H1 = styled.h1`
  font-family: Calibre;
  font-weight: 500;
  font-size: 36px;
  line-height: 40px;
  color: ${(props) => props.theme.colors.web.black};
`
export const H2 = styled.h2`
  font-family: Calibre;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  color: ${(props) => props.theme.colors.web.black};
`
export const H3 = styled.h3<IProps>`
  font-family: Calibre;
  font-weight: 500;
  font-size: 22px;
  line-height: 26.18px;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.web.black};
`
export const H3White = styled(H3)`
  color: ${(props) => props.theme.colors.web.white};
`
export const H4 = styled.h4`
  font-family: Calibre;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.web.black};
`

export const H5 = styled.h5<IProps>`
  font-family: Calibre;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.web.black};
`

export const H6 = styled.h6`
  font-family: Calibre;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.web.black};
`

export const BodyRegular = styled.p<IProps>`
  font-family: Calibre;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) =>
    props.color ? props.color : props.theme.colors.web.darker};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'auto')};
`

export const BodyRegularWhite = styled(BodyRegular)`
  color: ${(props) => props.theme.colors.web.white};
`
export const BodyBig = styled(BodyRegular)`
  font-size: 20px;
  line-height: 32px;
`
export const BodySmall = styled.p`
  font-family: Calibre;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.web.darker};
`

export const BodyXSmall = styled.p`
  font-family: Calibre;
  font-weight: 300;
  font-size: 14px;
  line-height: 16.66px;
  color: ${(props) => props.theme.colors.web.darker};
`

export const Footer = styled.p`
  font-family: Calibre;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.web.darker};
`

export const Details = styled.p<IProps>`
  font-family: Calibre;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => (props.color ? props.color : '#ff6b55')};
  text-transform: uppercase;
`

export const DesktopTitle = styled.p`
  font-family: Calibre;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #495358;
`

export const DesktopBody = styled.p`
  font-family: Calibre;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #495358;
`
