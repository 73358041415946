import { AxiosInstance } from 'axios'
import { Status } from '../enums'

export type VerifyResponseSuccess = {
  status: Status.Complete
  messageCode: string
  tokens: {
    accessToken: string
    refreshToken: string
  }
}

export type VerifyResponseFailedErrorPending = {
  status: Status.Error | Status.Failed | Status.Pending
  messageCode: string
}

export type VerifyResponse =
  | VerifyResponseSuccess
  | VerifyResponseFailedErrorPending

export const verifyAuthentication = (
  client: AxiosInstance,
  orderRef: string
) => {
  return client.post<VerifyResponse>('/v2/authentication/bankid/verify', {
    orderRef,
  })
}
