import { useTheme } from 'styled-components'
import { StylesConfig, GroupBase } from 'react-select'

const useSelectStyle = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>() => {
  const theme = useTheme()

  const style: StylesConfig<Option, IsMulti, Group> | undefined = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      width: '100%',
      cursor: 'pointer',
      padding: theme.margin.xxs,
      fontFamily: 'Calibre',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      borderBottom: `1px solid ${theme.colors.web.light}`,
      transition: 'color 0.1s ease-in-out, background-color 0.1s ease-in-out',
      color: isSelected ? theme.colors.web.white : theme.colors.web.darkest,
      backgroundColor: isSelected
        ? theme.colors.web.primary
        : isFocused
        ? theme.colors.web.light
        : 'transparent',
    }),
    container: (base, { isFocused }) => ({
      ...base,
      border: `1px solid ${
        isFocused ? theme.colors.web.primary : theme.colors.web.neutral
      }`,
      minHeight: '46px',
      borderRadius: '6px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      transition: 'border-color 0.1s ease-in-out',
    }),
    valueContainer: (base) => ({
      ...base,
      padding: theme.margin['3xs'],
    }),
    menu: (base) => ({
      ...base,
      border: `1px solid ${theme.colors.web.neutral}`,
    }),
    control: (base, { isDisabled }) => ({
      ...base,
      width: '100%',
      cursor: 'pointer',
      fontFamily: 'Calibre',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      color: theme.colors.web.darkest,
      border: 'none',
      boxShadow: 'none',
      backgroundColor: isDisabled
        ? theme.colors.web.light
        : theme.colors.web.white,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      borderRadius: theme.radius.rounded,
    }),
    placeholder: (defaultStyles) => ({
      ...defaultStyles,
      color: theme.colors.web.neutral,
    }),
    singleValue: (base, { isDisabled }) => ({
      ...base,
      opacity: isDisabled ? 0.3 : 1,
      transition: 'opacity 300ms',
      color: theme.colors.web.darker,
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: theme.colors.web.light,
      borderRadius: theme.radius.rounded,
      color: theme.colors.web.darkest,
      border: `0.5px solid ${theme.colors.web.neutral}`,
    }),
    multiValueRemove: () => ({
      color: theme.colors.web.darkest,
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      alignItems: ' center',
      marginRight: theme.margin['4xs'],
      transition: 'color 0.2s ease-in-out',

      ':hover': {
        color: theme.colors.web.primary,
      },
    }),
    multiValueLabel: () => ({
      fontFamily: 'Calibre',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      padding: `0 ${theme.margin['3xs']}`,
    }),
  }

  return style
}

export default useSelectStyle
