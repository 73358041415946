import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import { defaultTheme } from '@bonliva-ui/core/theme'
import React from 'react'
import {
  List,
  Show,
  ListButton,
  TopToolbar,
  Datagrid,
  ReferenceField,
  TextField,
  DateField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'

const treatmentPlanFilters = [
  <ReferenceInput
    key="treatmentplan-filter-patient"
    source="patientId"
    reference="patients"
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
]

export const TreatmentPlanList = () => (
  <List filters={treatmentPlanFilters}>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <ReferenceField
        label="Patient"
        reference="patients"
        source="patientId"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Psykolog"
        reference="treaters"
        source="treaterId"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Status" source="status" />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomShowbutton />
      </div>
    </Datagrid>
  </List>
)

export const TreatmentPlanShow = () => (
  <Show
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <TabbedShowLayout>
      <Tab
        label="Information"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField source="id" />
        <ReferenceField
          label="Patient"
          reference="patients"
          source="patientId"
          sortable={false}
        >
          <TextField label="Patient" source="name" />
        </ReferenceField>
        <ReferenceField
          label="Psykolog"
          reference="treaters"
          source="treaterId"
          sortable={false}
        >
          <TextField label="Psykolog" source="name" />
        </ReferenceField>
        <ReferenceField
          label="Behandlingsområde"
          reference="treatment-categories"
          source="treatmentCategoryId"
          sortable={false}
        >
          <TextField source="category" />
        </ReferenceField>
        <TextField source="status" label="Status" />
        <TextField source="information" label="Information" />
        <TextField
          source="rejectionMessage"
          label="Patientens anledning till avslag"
        />
      </Tab>
      <Tab
        label="Kommande bokningar"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="bookings"
          target="treatmentPlanId"
          label=""
          filter={{ startDate: `gte:${new Date().toISOString()}` }}
          sort={{ field: 'Booking.startDate', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <DateField source="startDate" label="Datum" sortable={false} />
            <ReferenceField
              source="treatmentId"
              reference="treatment-categories"
              label="Behandlingsområde"
              sortable={false}
            >
              <TextField source="category" />
            </ReferenceField>
            <TextField source="status" label="Status" sortable={false} />
            <CustomShowbutton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab
        label="Historik"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="bookings"
          target="treatmentPlanId"
          label=""
          filter={{ startDate: `lt:${new Date().toISOString()}` }}
          sort={{ field: 'Booking.startDate', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <DateField source="startDate" label="Datum" sortable={false} />
            <ReferenceField
              source="treatmentId"
              reference="treatment-categories"
              label="Behandlingsområde"
              sortable={false}
            >
              <TextField source="category" />
            </ReferenceField>
            <TextField source="status" label="Status" sortable={false} />
            <CustomShowbutton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
