import React from 'react'
import { MenuItemLink, Menu, useSidebarState } from 'react-admin'
import { useStyles } from './AdminMenu.style'
import { Icon } from '@bonliva-traits/admin-icons'
import { Icon as WebIcon } from '@bonliva-traits/web-icons'
import { IconType } from '@bonliva-traits/admin-icons'
import { defaultTheme } from '@bonliva-ui/core/theme'
import { useLogout } from 'react-admin'
import { useIdentity } from './useIdentity'

type Links = {
  label: string
  path: string
  icon: keyof typeof IconType
}[]

const navItems: Links = [
  {
    label: 'Bokningar',
    path: '/bookings',
    icon: IconType.Calendar,
  },
  {
    label: 'Patienter',
    path: '/patients',
    icon: IconType.Avatar,
  },
  {
    label: 'Psykologer',
    path: '/treaters',
    icon: IconType.AvatarMultiple,
  },
  {
    label: 'Admins',
    path: '/admins',
    icon: IconType.Avatar,
  },
  {
    label: 'Vårdgivare',
    path: '/care-providers',
    icon: IconType.AvatarTwo,
  },
  {
    label: 'Remisser',
    path: '/referral-plans',
    icon: IconType.ReferralPlan,
  },
  {
    label: 'Behandlingsplaner',
    path: '/treatment-plans',
    icon: IconType.TreatmentPlan,
  },
  {
    label: 'Specialistkompetenser',
    path: '/specialist-competences',
    icon: IconType.SpecialistCompetence,
  },
  {
    label: 'Behandlingsområden',
    path: '/treatment-categories',
    icon: IconType.TreatmentCategory,
  },
  {
    label: 'Competence <=> Category',
    path: '/specialist-competence-treatment-category',
    icon: IconType.TreatmentCategory,
  },
  {
    label: 'Filer',
    path: '/files',
    icon: IconType.Files,
  },
  {
    label: 'Feedback',
    path: '/booking-feedbacks',
    icon: IconType.Feedback,
  },
  {
    label: 'Feedback - Diagram',
    path: '/feedback-kpis',
    icon: IconType.FeedbackDiagram,
  },
  {
    label: 'Analys - Sammanfattning',
    path: '/analysis-summary',
    icon: IconType.AnalysisSummary,
  },
  {
    label: 'Analys - Diagram',
    path: '/analysis-kpis',
    icon: IconType.AnalysisDiagram,
  },
  {
    label: 'Fortnox',
    path: '/fortnox',
    icon: IconType.Fortnox,
  },
]

const AdminMenu = () => {
  const classes = useStyles()
  const logout = useLogout()
  const [open] = useSidebarState()
  const user = useIdentity()

  return (
    <Menu
      sx={{
        height: '100vh',
        marginTop: '0',
        marginBottom: '0',
        paddingTop: '0',
        paddingBottom: '0',
        position: 'static',
      }}
    >
      <div className={classes.root}>
        <div className={classes.logoWrapper}>
          <WebIcon src="BonlivaLogo" className={classes.logo} />
        </div>
        <div className={classes.menuWrapper}>
          {navItems.map(({ label, path, icon }) => {
            return (
              <MenuItemLink
                key={label}
                primaryText={label}
                sx={{
                  color: 'white!important',
                  width: '100%',
                  fontSize: '18px',
                  cursor: 'pointer',
                  padding: defaultTheme.margin['3xs'],
                  borderRadius: defaultTheme.radius.rounded,
                  '&.RaMenuItemLink-active': {
                    backgroundColor: defaultTheme.colors.admin.blue,
                  },
                }}
                to={path}
                leftIcon={
                  <Icon
                    width={24}
                    height={24}
                    src={icon}
                    style={{ color: 'white' }}
                  />
                }
              />
            )
          })}
        </div>
        {open && (
          <div className={classes.cardWrapper}>
            <span className={classes.name}>{user?.identity.data?.name}</span>
            <button className={classes.logoutButton} onClick={() => logout()}>
              <WebIcon src="Logout" />
              Logga ut
            </button>
          </div>
        )}
      </div>
    </Menu>
  )
}

export default AdminMenu
