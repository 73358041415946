export enum UserType {
  Patient = 'patient',
  Treater = 'treater',
  Admin = 'admin',
}

export enum PlatformType {
  Web = 'web',
  App = 'app',
}

export enum Status {
  Pending = 'pending',
  Failed = 'failed',
  Complete = 'complete',
  Error = 'error',
}
