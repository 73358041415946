import { useUnloadAppointments } from '@bonliva-traits/api/appointments/handlers/unloadAppointments'
import { useUnloadArticles } from '@bonliva-traits/api/articles/handlers/unloadArticles'
import { useUnloadAssignments } from '@bonliva-traits/api/assignments/handlers/unloadAssignments'
import { useUnloadHealthCurve } from '@bonliva-traits/api/health-curve/handlers/unloadHealthCurve'
import { useUnloadMediaFavorites } from '@bonliva-traits/api/media-favorites/handlers/unloadMediaFavorites'
import { useUnloadMediaLibrary } from '@bonliva-traits/api/media-library/handlers/unloadMediaLibrary'
import { useUnloadNotifications } from '@bonliva-traits/api/notifications/handlers/unloadNotifications'
import { useUnloadPatientProfile } from '@bonliva-traits/api/patient-profile/handlers/unloadPatientProfile'
import { useUnloadTreaterProfile } from '@bonliva-traits/api/treater-profile/handlers/unloadTreaterProfile'
import { useUnloadTreaters } from '@bonliva-traits/api/treaters/handlers/unloadTreaters'
import { useUnloadTreatmentCategories } from '@bonliva-traits/api/treatment-categories/handlers/unloadTreatmentCategories'
import { useUnloadTreatmentForm } from '@bonliva-traits/api/treatment-form/handlers/unloadTreatmentForm'

export const useUnloadAll = () => {
  const unloadAppointments = useUnloadAppointments()
  const unloadArticles = useUnloadArticles()
  const unloadAssignments = useUnloadAssignments()
  const unloadHealthCurve = useUnloadHealthCurve()
  const unloadMediaFavorites = useUnloadMediaFavorites()
  const unloadMediaLibrary = useUnloadMediaLibrary()
  const unloadNotifications = useUnloadNotifications()
  const unloadPatientProfile = useUnloadPatientProfile()
  const unloadTreaterProfile = useUnloadTreaterProfile()
  const unloadTreaters = useUnloadTreaters()
  const unloadTreatmentCategories = useUnloadTreatmentCategories()
  const unloadTreatmentForm = useUnloadTreatmentForm()

  return () => {
    unloadAppointments()
    unloadArticles()
    unloadAssignments()
    unloadHealthCurve()
    unloadMediaFavorites()
    unloadMediaLibrary()
    unloadNotifications()
    unloadPatientProfile()
    unloadTreaterProfile()
    unloadTreaters()
    unloadTreatmentCategories()
    unloadTreatmentForm()
  }
}
