import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  TopToolbar,
  ListButton,
} from 'react-admin'
import CustomEditButton from '@bonliva-admin/custom-editbutton'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import CustomDeleteButton from '@bonliva-admin/custom-deletebutton'

export const SpecialistCompetenceList = () => (
  <List perPage={25} sort={{ field: 'name', order: 'ASC' }}>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <TextField label="Namn" source="name" sortable={false} />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomDeleteButton />
        <CustomEditButton callToAction={true} />
      </div>
    </Datagrid>
  </List>
)

export const SpecialistCompetenceCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput label="Namn" source="name" />
    </SimpleForm>
  </Create>
)

export const SpecialistCompetenceEdit = () => (
  <Edit
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput label="Namn" source="name" />
    </SimpleForm>
  </Edit>
)
