import { createStore, RootCreatorTypes } from '@bonliva-core/store'
import { unique } from '@bonliva-traits/utils'
import { State, Action, Types } from './types'

const initialState: State = {
  isLoading: false,
  hasLoaded: false,
  hasMore: true,
  page: 0,
  data: [],
  count: 0,
}

const store = createStore<State, Action>({
  name: 'media-favorites',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case RootCreatorTypes.UNLOAD:
        return initialState

      case RootCreatorTypes.REFRESH:
        return {
          ...state,
          hasLoaded: false,
          isLoading: true,
          hasMore: true,
          page: 0,
          data: [],
        }

      case RootCreatorTypes.PENDING:
        return {
          ...state,
          isLoading: true,
          hasError: undefined,
        }

      case RootCreatorTypes.SUCCESS:
        return {
          ...state,
          hasLoaded: true,
          isLoading: false,
          page: state.hasMore ? state.page + 1 : state.page,
          data: unique(state.data, action.payload)('id'),
        }

      case Types.CREATE_FAVORITE:
        return {
          ...state,
          count: state.count + 1,
          data: [...state.data, action.payload],
        }

      case Types.DELETE_FAVORITE:
        return {
          ...state,
          count: state.count - 1,
          data: state.data.filter((item) => item.id !== action.payload),
        }

      case RootCreatorTypes.HAS_MORE:
        return {
          ...state,
          hasMore: action.payload - state.data.length > 0,
          count: Number(action.payload),
        }

      case RootCreatorTypes.FAIL:
        return {
          ...state,
          isLoading: false,
          hasError: action.payload,
        }

      default:
        return state
    }
  },
})

export default store
