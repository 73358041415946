const shadow = (elevation: number) => ({
  elevation,
  shadowRadius: 1 * elevation,
  shadowColor: '#BFBFBF',
  shadowOpacity: 0.15,
  shadowOffset: {
    width: 0,
    height: 5,
  },
})

export const defaultTheme = {
  colors: {
    web: {
      logo: '#495358',
      integrationBonlivaLogo: '#EFA86B',
      bankidlogo: '#183E4F',
      primary: '#FF867B',
      primaryDark: '#C8564F',
      primaryLight: '#FFB8AA',
      secondary: '#3E586D',
      secondaryDark: '#122F42',
      secondaryLight: '#E2EFFF',
      black: '#3A4043',
      darkest: '#495358',
      darker: '#727272',
      dark: '#8A8A8A',
      neutral: '#BFBFBF',
      neutralLight: '#DDDDDD',
      neutralLighter: '#EAEAEA',
      light: '#E5E9ED',
      lighter: '#F6F3F0',
      lightest: '#F8F8F8',
      white: '#FFFFFF',
      success: '#4CD964',
      info: '#2A6DDD',
      error: '#FF312E',
      lightError: '#FAEDEA',
      rejected: '#FFBF1C',
    },
    native: {
      bankidlogo: '#183E4F',
      primary: '#FF867B',
      primaryDark: '#C8564F',
      primaryLight: '#FFB8AA',
      secondary: '#3E586D',
      secondaryDark: '#122F42',
      secondaryLight: '#E2EFFF',
      black: '#121212',
      darkest: '#363636',
      darker: '#727272',
      dark: '#8A8A8A',
      neutral: '#BFBFBF',
      neutralLight: '#DDDDDD',
      neutralLighter: '#EAEAEA',
      light: '#F6F3F0',
      lighter: '#F8F8F8',
      lightest: '#EAEAEA',
      white: '#FFFFFF',
      '100%': '#000000FF',
      '80%': '#000000CC',
      '60%': '#00000099',
      '40%': '#00000066',
      '20%': '#00000033',
      transparent: '#00000000',
      warning: '#d14343',
      success: '#4CD964',
      info: '#2A6DDD',
      error: '#FF312E',
      'white-16%': '#FFFFFF29',
      'primary-16%': '#FF867B29',
      'primary-8%': '#FF867B14',
      gray: '#686E75',
      background: '#F2F2F2',
    },
    admin: {
      bankidlogo: '#183E4F',
      primary: '#FF867B',
      primaryDark: '#C8564F',
      primaryLight: '#FFB8AA',
      secondary: '#3E586D',
      secondaryDark: '#122F42',
      secondaryLight: '#E2EFFF',
      black: '#3A4043',
      darkest: '#495358',
      darker: '#727272',
      dark: '#8A8A8A',
      neutral: '#BFBFBF',
      light: '#E5E9ED',
      lighter: '#F6F3F0',
      lightest: '#F8F8F8',
      white: '#FFFFFF',
      success: '#4CD964',
      info: '#2A6DDD',
      error: '#FF312E',
      lightError: '#FAEDEA',
      blue: '#3E586D',
      darkBlue: '#122F42',
      lightBlue: '#E2EFFF',
      background: '#E5E9ED',
      darkGray: '#3C3733',
    },
    bookingWeb: {
      bankidlogo: '#183E4F',
      primary: '#EFA86B',
      primaryDark: '#CB8E59',
      primaryLight: '#FBF4EE',
      lighter: '#F8F8F8',
      dark: '#8A8A8A',
      darker: '#727272',
      darkest: '#363636',
      lightError: '#FAEDEA',
      error: '#C8564F',
      lightSuccess: '#f4f9f5',
      success: '#5b8367',
      faded: '#ffe6d0',
      grey: '#bfbfbf',
      lightGrey: '#efefef',
      white: '#FFF',
      black: '#121212',
      blue: '#2B2C4F',
    },
  },

  margin: {
    none: '0',
    '4xs': '4px',
    '3xs': '8px',
    xxs: '16px',
    xs: '24px',
    s: '32px',
    m: '40px',
    l: '48px',
    xl: '56px',
    xxl: '64px',
    '3xl': '72px',
    '4xl': '80px',
    auto: 'auto',
  },

  align: {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  },

  justify: {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
    even: 'space-evenly',
    around: 'space-around',
    between: 'space-between',
  },

  radius: {
    rounded: '8px',
    roundedSm: '4px',
    card: '16px',
    full: '9999px',
  },

  shadow: {
    popup: shadow(10),
    high: shadow(7),
    mid: shadow(4),
    low: shadow(2),
  },
} as const

export type DefaultTheme = typeof defaultTheme
