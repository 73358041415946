import * as React from 'react'
import { ResponseError } from '@bonliva-core/store'
import {
  AxiosError,
  AxiosRequestConfig,
  RawAxiosResponseHeaders,
  AxiosResponseHeaders,
} from 'axios'
import { useApi } from '@bonliva-traits/api'

function useApiState<T = unknown>(url: string, defaultValue?: T) {
  const api = useApi()
  const controllerRef = React.useRef<AbortController>(new AbortController())

  const [data, setData] = React.useState(
    defaultValue ? (defaultValue as T) : undefined
  )

  const [headers, setHeaders] = React.useState<
    RawAxiosResponseHeaders | AxiosResponseHeaders
  >({})
  const [error, setError] = React.useState<ResponseError<T>>()
  const [hasError, setHasError] = React.useState(false)
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const unload = React.useCallback(() => {
    setData(defaultValue as T)
    setError(undefined)
    setHasError(false)
    setHasLoaded(false)
    setIsLoading(false)
  }, [])

  const cancel = React.useCallback(() => {
    controllerRef.current.abort()
  }, [])

  const fetch = React.useCallback(
    (method: string) =>
      async (payload?: AxiosRequestConfig, extendUrl = '') => {
        const signal = controllerRef.current?.signal

        setIsLoading(true)
        setHasError(false)

        try {
          const res = await api.request<T>({
            url: extendUrl ? url + '/' + extendUrl : url,
            method,
            ...payload,
            ...(signal && { signal }),
          })

          setHeaders(res.headers)
          setData(res.data)
          return res.data
        } catch (err) {
          setHasError(true)
          if (!(err as AxiosError).response) throw err
          setError((err as AxiosError).request.data)
          throw new Error("Can't fetch data")
        } finally {
          setIsLoading(false)
          setHasLoaded(true)
        }
      },
    [url]
  )

  return {
    data,
    setData,
    error,
    hasError,
    hasLoaded,
    isLoading,
    unload,
    cancel,
    headers,
    get: fetch('get'),
    delete: fetch('delete'),
    head: fetch('head'),
    options: fetch('options'),
    post: fetch('post'),
    put: fetch('put'),
    patch: fetch('patch'),
  }
}

export default useApiState
