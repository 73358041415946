import { createGlobalStyle } from 'styled-components'
import CalibreRegular from '@bonliva-ui/assets/fonts/Calibre-Regular.otf'
import CalibreBlack from '@bonliva-ui/assets/fonts/Calibre-Black.otf'
import CalibreBlackItalic from '@bonliva-ui/assets/fonts/Calibre-BlackItalic.otf'
import CalibreBold from '@bonliva-ui/assets/fonts/Calibre-Bold.otf'
import CalibreBoldItalic from '@bonliva-ui/assets/fonts/Calibre-BoldItalic.otf'
import CalibreLight from '@bonliva-ui/assets/fonts/Calibre-Light.otf'
import CalibreLightItalic from '@bonliva-ui/assets/fonts/Calibre-LightItalic.otf'
import CalibreMedium from '@bonliva-ui/assets/fonts/Calibre-Medium.otf'
import CalibreMediumItalic from '@bonliva-ui/assets/fonts/Calibre-MediumItalic.otf'
import CalibreRegularItalic from '@bonliva-ui/assets/fonts/Calibre-RegularItalic.otf'
import CalibreSemiBold from '@bonliva-ui/assets/fonts/Calibre-Semibold.otf'
import CalibreSemiBoldItalic from '@bonliva-ui/assets/fonts/Calibre-SemiboldItalic.otf'
import CalibreThin from '@bonliva-ui/assets/fonts/Calibre-Thin.otf'
import CalibreThinItalic from '@bonliva-ui/assets/fonts/Calibre-ThinItalic.otf'
import OpenSansItalic from '@bonliva-ui/assets/fonts/OpenSans-Italic.ttf'
import OpenSansMedium from '@bonliva-ui/assets/fonts/OpenSans-Medium.ttf'
import OpenSansMediumItalic from '@bonliva-ui/assets/fonts/OpenSans-MediumItalic.ttf'
import OpenSansRegular from '@bonliva-ui/assets/fonts/OpenSans-Regular.ttf'
import OpenSansSemiBold from '@bonliva-ui/assets/fonts/OpenSans-SemiBold.ttf'
import OpenSansSemiBoldItalic from '@bonliva-ui/assets/fonts/OpenSans-SemiBoldItalic.ttf'

export const GlobalStyles = createGlobalStyle`

* {
  box-sizing: border-box;
}

input {
  &:focus {
    outline: 1px solid #3E586D;
  }
}

html {
  background-color: #FFF;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
 margin: 0;
 padding: 0;
 border: 0;
 font-size: 100%;
 font: inherit;
 vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
 display: block;
}
body {
 line-height: 1;
}
ol, ul {
 list-style: none;
}
blockquote, q {
 quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
 content: '';
 content: none;
}
table {
 border-collapse: collapse;
 border-spacing: 0;
}
body, #root {
    height: 100vh;
    font-family: "Calibre", sans-serif;
}

#popup > * {
  -webkit-animation: popup 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: popup 0.2s; /* Firefox < 16 */
  -ms-animation: popup 0.2s; /* Internet Explorer */
  -o-animation: popup 0.2s; /* Opera < 12.1 */
  animation: popup 0.2s;

  @keyframes popup {
    0% {
      opacity: 0;
      transform: translateY(-2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  /* Firefox < 16 */
  @-moz-keyframes popup {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
  
  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes popup {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
  }
}


@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 400; 
    src: url(${CalibreRegular}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 900; 
    src: url(${CalibreBlack}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 400; 
    src: url(${CalibreBlackItalic}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 700; 
    src: url(${CalibreBold}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 700; 
    src: url(${CalibreBoldItalic}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 300; 
    src: url(${CalibreLight}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 300; 
    src: url(${CalibreLightItalic}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 500; 
    src: url(${CalibreMedium}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 500; 
    src: url(${CalibreMediumItalic}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 400; 
    src: url(${CalibreRegularItalic}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 600; 
    src: url(${CalibreSemiBold}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 600; 
    src: url(${CalibreSemiBoldItalic}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: normal; 
    font-weight: 100; 
    src: url(${CalibreThin}) format('opentype')
}
@font-face {
    font-family: Calibre;
    font-style: italic; 
    font-weight: 100; 
    src: url(${CalibreThinItalic}) format('opentype')
}
@font-face {
    font-family: "Open Sans";
    font-style: normal; 
    font-weight: 400; 
    src: url(${OpenSansRegular}) format('opentype')
}
@font-face {
    font-family: "Open Sans";
    font-style: italic; 
    font-weight: 400; 
    src: url(${OpenSansItalic}) format('opentype')
}
@font-face {
    font-family: "Open Sans";
    font-style: normal; 
    font-weight: 500; 
    src: url(${OpenSansMedium}) format('opentype')
}
@font-face {
    font-family: "Open Sans";
    font-style: italic; 
    font-weight: 500; 
    src: url(${OpenSansMediumItalic}) format('opentype')
}
@font-face {
    font-family: "Open Sans";
    font-style: normal; 
    font-weight: 600; 
    src: url(${OpenSansSemiBold}) format('opentype')
}
@font-face {
    font-family: "Open Sans";
    font-style: italic; 
    font-weight: 600; 
    src: url(${OpenSansSemiBoldItalic}) format('opentype')
}


`
