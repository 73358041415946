import { useApi } from '@bonliva-traits/api'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { Chip, Paper, Toolbar, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { Button } from 'react-admin'

type FortnoxResponse = {
  isConfigured: boolean
}

export const FortnoxPage: React.FC = () => {
  const api = useApi()
  const { data: fortnox, get } =
    useApiState<FortnoxResponse>('v1/admin/fortnox')

  const connect = () => {
    api.get('v1/admin/fortnox/connect').then((response) => {
      window.open(response.data.connectUrl)
    })
  }

  useEffect(() => {
    get()
  }, [])

  return (
    <>
      <Toolbar></Toolbar>
      <Paper style={{ padding: 16 }} elevation={2}>
        <Typography variant="h6">
          Status för Fortnox:{' '}
          <Chip label={fortnox?.isConfigured ? 'Ansluten' : 'Inte ansluten'} />
        </Typography>

        <div>
          {!fortnox?.isConfigured && (
            <Button onClick={connect} label="Koppla till" />
          )}
        </div>
      </Paper>
    </>
  )
}
