import styled, { css } from 'styled-components'

type Button = {
  width?: string
  minWidth?: string
  align?: string
  color?: string
  margin?: string
  disabled?: boolean
}

type RoundButton = {
  color: string
  backgroundColor: string
  svgHeight?: string
  svgWidth?: string
  height?: string
  width?: string
  border?: string
}

export const ButtonPrimary = styled.button<Button>`
  background-color: ${(props) => props.theme.colors.web.primary};
  color: ${(props) => props.theme.colors.web.white};
  border: 1px solid ${(props) => props.theme.colors.web.primaryDark}11;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  font-family: 'Calibre', sans-serif;
  width: ${(props) => props.width || 'auto'};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  margin: ${(props) => props.margin || 'none'};
  min-width: max-content;

  transition: filter 0.1s ease-in-out, background-color 0.1s ease-in-out,
    border 0.1s ease-in-out, color 0.1s ease-in-out;

  ${({ disabled, theme }) =>
    disabled
      ? css`
          background-color: ${theme.colors.web.light};
          color: ${theme.colors.web.dark};
          border: 1px solid ${theme.colors.web.neutral};

          &:hover {
            filter: brightness(95%);
          }
        `
      : css`
          &:hover {
            filter: brightness(125%);
          }
        `}
`

export const ButtonPrimaryLarge = styled(ButtonPrimary)`
  padding: 16px;
  justify-content: center;
`

export const ButtonSecondary = styled(ButtonPrimary)`
  background-color: ${(props) => props.theme.colors.web.white};
  color: ${(props) => props.theme.colors.web.primary};
  border: 1px solid ${(props) => props.theme.colors.web.primary};
  align-items: center;
  justify-content: center;
  min-width: max-content;

  ${({ disabled, theme }) =>
    disabled
      ? css`
          background-color: ${theme.colors.web.light};
          color: ${theme.colors.web.dark};
          border: 1px solid ${theme.colors.web.neutral};
        `
      : css`
          &:hover {
            filter: brightness(125%);
          }
        `}
`

export const ButtonSecondaryLarge = styled(ButtonSecondary)`
  padding: 16px;
`

export const ButtonPrimaryText = styled(ButtonPrimary)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.web.primary};
  border-color: transparent;
`

export const ButtonPrimaryTextLarge = styled(ButtonPrimaryText)`
  padding: 16px;
`

export const RoundButtonPrimary = styled.button<RoundButton>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => (props.border ? props.border : 'none')};
  border-radius: 50%;
  margin: 4px 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || '40px'};
  width: ${(props) => props.width || '40px'};
  border: ${(props) => props.border || 'none'};

  svg {
    height: ${(props) => props.svgHeight || '10px'};
    width: ${(props) => props.svgWidth || '10px'};
  }
`

export const InvisibleButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  cursor: pointer;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const UnderlineButton = styled(ButtonSecondary)<Button>`
  font-weight: 400;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #ff867b;
  padding: 0;
  border-radius: 0;
`
