import React from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import {
  List,
  TextField,
  Datagrid,
  Show,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  Create,
  TextInput,
  required,
  ListButton,
  TopToolbar,
  SearchInput,
  Edit,
  minLength,
  regex,
} from 'react-admin'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import CustomEditbutton from '@bonliva-admin/custom-editbutton'
import { validateSSN } from '../helpers/validateSSN'

const adminFilters = [
  <SearchInput key="admins-filter-admin" source="_search" alwaysOn />,
]

export const AdminList = () => (
  <List filters={adminFilters} sort={{ field: 'Admin.name', order: 'ASC' }}>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <TextField label="Namn" source="name" sortable={false} />
      <TextField
        label="Personnummer"
        source="personalNumber"
        sortable={false}
      />
      <TextField label="E-mail" source="email" sortable={false} />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomEditbutton />
        <CustomShowbutton />
      </div>
    </Datagrid>
  </List>
)

export const AdminShow = () => (
  <Show
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <TabbedShowLayout>
      <Tab
        label="Admininformation"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField source="name" label="Namn" />
        <TextField source="personalNumber" label="Personnummer" />
        <TextField source="email" label="E-mail" />
        <TextField source="id" label="Id" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const AdminCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput
        label="Personnummer"
        source="personalNumber"
        validate={[
          required('Du måste fylla i personnummer'),
          minLength(12, 'Personnumret måste vara 12 siffror'),
          regex(/^[0-9]*$/, 'Personnumret får bara innehålla siffror'),
          validateSSN,
        ]}
      />
      <TextInput label="Namn" source="name" validate={[required()]} />
      <TextInput label="E-mail" source="email" />
    </SimpleForm>
  </Create>
)

export const AdminEdit = () => (
  <Edit
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput
        label="Personnummer"
        source="personalNumber"
        validate={[required()]}
        disabled={true}
      />
      <TextInput label="Namn" source="name" validate={[required()]} />
      <TextInput label="E-mail" source="email" validate={[required()]} />
    </SimpleForm>
  </Edit>
)
