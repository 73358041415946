import { AxiosInstance } from 'axios'
import { PlatformType, UserType } from '../enums'

export type StartAuthenticationResponse = {
  autoStartToken: string
  orderRef: string
}

export const startAuthentication = (
  client: AxiosInstance,
  userType: UserType,
  platformType: PlatformType,
  demoMode?: boolean
) => {
  return client.post<StartAuthenticationResponse>(
    '/v2/authentication/bankid/start',
    {
      userType,
      platformType,
      demoMode,
    }
  )
}
