import React from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import { SaveButton, Toolbar } from 'react-admin'

const CustomSaveButton = () => {
  return (
    <Toolbar>
      <SaveButton
        icon={<></>}
        sx={{
          backgroundColor: defaultTheme.colors.admin.primary,
          border: '1px solid transparent',
          color: 'white',
          fontWeight: 'bold',
          padding: defaultTheme.margin['3xs'],
          margin: '0 6px',
        }}
        label="Spara"
      />
    </Toolbar>
  )
}

export default CustomSaveButton
