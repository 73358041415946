import Axios from 'axios'
import { useConfig } from '@bonliva-traits/config'

export const useAuthApi = () => {
  const { API_URL } = useConfig()

  const client = Axios.create({
    baseURL: API_URL,
  })

  return client
}
