import { PlatformType } from '@bonliva-auth/api'
import React, { PropsWithChildren } from 'react'
import { BaseAuthProvider } from './baseProvider'
import { Status } from './context'

type Props = {
  platformType: PlatformType
}

const AuthProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const getRefreshToken = async () => {
    // condition for server side rendering where window is undefined
    if (typeof window === 'undefined') return Promise.resolve('')
    return window.localStorage.getItem('refreshToken') || ''
  }

  const setRefreshToken = async (refreshToken: string) => {
    // condition for server side rendering where window is undefined
    if (typeof window === 'undefined') return Promise.resolve()
    window.localStorage.setItem('refreshToken', refreshToken)
  }

  return (
    <BaseAuthProvider
      platformType={props.platformType}
      getRefreshToken={getRefreshToken}
      setRefreshToken={setRefreshToken}
      initialState={{
        status: Status.Authenticating,
        isLoading: false,
      }}
    >
      {props.children}
    </BaseAuthProvider>
  )
}

export default AuthProvider
