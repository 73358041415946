import { defaultTheme } from '@bonliva-ui/core/theme'
import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    color: 'white',
    backgroundColor: defaultTheme.colors.admin.darkBlue,
    padding: defaultTheme.margin['3xs'],
    paddingBottom: '5em',
  },
  menuWrapper: {
    height: '85%',
    backgroundColor: defaultTheme.colors.admin.darkBlue,
    paddingBottom: '10px',
    overflowY: 'scroll',
  },
  logoWrapper: {
    display: 'flex',
    marginLeft: '5px',
  },
  logo: {
    width: '30px',
    color: defaultTheme.colors.admin.primary,
  },
  cardWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: defaultTheme.margin.s,
    paddingBottom: defaultTheme.margin.s,
    marginRight: defaultTheme.margin.s,
    marginLeft: defaultTheme.margin.s,
    borderTop: '1px solid rgba(255, 255, 255, 0.15)',
    height: '15%',
    backgroundColor: defaultTheme.colors.admin.darkBlue,
  },
  profileImg: {
    width: '50px',
    height: '50px',
    borderRadius: defaultTheme.radius.rounded,
    marginBottom: defaultTheme.margin['3xs'],
  },
  name: {
    fontWeight: 'bold',

    marginBottom: defaultTheme.margin.xs,
  },
  email: {},
  logoutButton: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px 16px',
    gap: '8px',
    background: defaultTheme.colors.admin.primary,
    borderRadius: defaultTheme.radius.rounded,

    cursor: 'pointer',
    border: 'none',

    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '24px',
  },
})
