import {
  ActionMap,
  ResponseError,
  RootCreatorPayload,
} from '@bonliva-core/store'
import { INotification } from '../types'

export type State = {
  hasLoaded: boolean
  isLoading: boolean
  page: number
  hasMore: boolean
  count: number
  hasError?: ResponseError<INotification>
  data: INotification[]
  unreadCount: number
}

export enum Types {
  SET_NOTIFICATION = 'SET_NOTIFICATION',
  REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
  SET_UNREAD_COUNT = 'SET_UNREAD_COUNT',
}

export type Payload = RootCreatorPayload<INotification[]> & {
  [Types.SET_NOTIFICATION]: INotification
  [Types.REMOVE_NOTIFICATION]: string
  [Types.SET_UNREAD_COUNT]: number
}

export type Action = ActionMap<Payload>
