import React, { SVGProps } from 'react'

const icons = {
  Calendar: require('./assets/calendar.svg'),
  AnalysisDiagram: require('./assets/analysis-diagram.svg'),
  AnalysisSummary: require('./assets/analysis-summary.svg'),
  AvatarMultiple: require('./assets/avatar-multiple.svg'),
  AvatarTwo: require('./assets/avatar-two.svg'),
  Avatar: require('./assets/avatar.svg'),
  FeedbackDiagram: require('./assets/feedback-diagram.svg'),
  Feedback: require('./assets/feedback.svg'),
  Files: require('./assets/files.svg'),
  Fortnox: require('./assets/fortnox.svg'),
  ReferralPlan: require('./assets/referral-plan.svg'),
  SpecialistCompetence: require('./assets/specialist-competence.svg'),
  TreatmentCategory: require('./assets/treatment-category.svg'),
  TreatmentPlan: require('./assets/treatment-plan.svg'),
} as const

export const IconType = Object.keys(icons).reduce((acc, key) => {
  const value = key as keyof typeof icons
  acc[value] = value
  return acc
}, {} as Record<keyof typeof icons, keyof typeof icons>)

interface Props extends SVGProps<SVGSVGElement> {
  src: keyof typeof icons
}

export const Icon: React.FC<Props> = (props) => {
  const icon = icons[props.src]

  return React.createElement(icon.ReactComponent, props)
}
