import styled from 'styled-components'

export const TextArea = styled.textarea`
  border: 1px solid ${({ theme }) => theme.colors.web.neutral};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  font-family: 'Calibre';
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  resize: vertical;
  min-height: 46px;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.web.primary};
  }
`
