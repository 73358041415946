import React from 'react'
import { EditButton } from 'react-admin'
import { defaultTheme } from '@bonliva-ui/core/theme'

interface CustomEditButtonProps {
  callToAction?: boolean
}

const CustomEditButton = ({ callToAction }: CustomEditButtonProps) => {
  if (callToAction) {
    return (
      <EditButton
        sx={{
          backgroundColor: defaultTheme.colors.admin.primary,
          border: '1px solid transparent',
          color: 'white',
          fontWeight: 'bold',
          padding: defaultTheme.margin['3xs'],
          margin: '0 6px',
          ':hover': {
            backgroundColor: defaultTheme.colors.admin.primary,
          },
          ':only-child': {
            margin: '0 0',
          },
        }}
        label="Redigera"
      />
    )
  } else {
    return (
      <EditButton
        sx={{
          border: `1px solid ${defaultTheme.colors.admin.primary}`,
          backgroundColor: 'white',
          color: defaultTheme.colors.admin.primary,
          fontWeight: 'bold',
          padding: defaultTheme.margin['3xs'],
          ':hover': {
            backgroundColor: 'white',
          },
        }}
        label="Redigera"
      />
    )
  }
}

export default CustomEditButton
