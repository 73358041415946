import React from 'react'
import ReactSelect, { Props, GroupBase } from 'react-select'
import useSelectStyle from './Select.style'

const Select = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group> & { doNotUseMenuPortalTarget?: boolean } // In some views, the 'getElementById' call below gives infinitely recursive rendering
) => {
  const styles = useSelectStyle<Option, IsMulti, Group>()

  return (
    <ReactSelect
      {...props}
      noOptionsMessage={() => 'Inga alternativ matchar din sökning'}
      menuPortalTarget={
        props.doNotUseMenuPortalTarget
          ? undefined
          : document.getElementById('popup') || document.body
      }
      styles={styles}
    />
  )
}

export default Select
