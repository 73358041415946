import React, { useMemo, useRef } from 'react'
import 'chart.js/auto'
import { Bar, Pie } from 'react-chartjs-2'
import { useTheme } from 'styled-components'
import * as S from './Graph.style'
import { getLabel } from '../Dashboard/getLabel'

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
    },
  },
  maintainAspectRatio: false,
}

type GraphProps = {
  label: string
  data:
    | {
        label: string
        value: string
      }[]
    | []
}

export const Graph = (props: GraphProps) => {
  const ref = useRef()
  const theme = useTheme()

  const labels = useMemo(() => {
    return props.data.map((item) => String(item.label))
  }, [props.data])

  const values = useMemo(() => {
    return props.data.map((item) => Number(item.value))
  }, [props.data])

  const totalValues = useMemo(() => {
    return values.reduce((a, b) => a + b, 0)
  }, [props.data])

  const graphData = {
    labels,
    datasets: [
      {
        data: values,
        label: getLabel(props.label),
        legend: false,
        borderWidth: 3,
        fill: true,
        borderColor: ['white'],
        backgroundColor: [
          '#84423B',
          '#C8564F',
          '#DFB8B4',
          '#CC8A83',
          '#590700',
          '#FFB8AA',
          '#FF867B',
        ],
        hoverBackgroundColor: [theme.colors.admin.primary],

        pointBackgroundColor: 'white',
      },
    ],
    redraw: true,
  }

  if (!props.data.length)
    return (
      <div>Det finns inte tillräckligt med data för att visa diagrammet</div>
    )
  if (props.label === 'rating') {
    return (
      <>
        <span>Totalt antal svar: {totalValues}</span>
        <S.GraphContainer>
          <Pie
            ref={ref}
            options={options}
            data={graphData}
            height="350px"
            width="800px"
          />
        </S.GraphContainer>
      </>
    )
  }
  return (
    <>
      <span>Totalt antal svar: {totalValues}</span>
      <S.GraphContainer>
        <Bar
          ref={ref}
          options={options}
          data={graphData}
          height={'400px'}
          width={'800px'}
        />
      </S.GraphContainer>
    </>
  )
}
