import defaults from './data/defaults.json'
import development from './data/development.json'
import staging from './data/staging.json'
import production from './data/production.json'
import bonlivaInternal from './data/bonliva-internal.json'

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
  BonlivaInternal = 'bonliva-internal',
}

export const getEnvironment = (): Environment => {
  return (
    (process.env.REACT_APP_CONFIG_FLAVOR as Environment) ||
    (process.env.NEXT_PUBLIC_CONFIG_FLAVOR as Environment) ||
    Environment.Production
  )
}

export type Config = typeof defaults &
  typeof development &
  typeof staging &
  typeof production &
  typeof bonlivaInternal

export const getConfig = (_env?: Environment): Config => {
  const env = _env || getEnvironment()

  const configs: Record<Environment, Partial<Config>> = {
    [Environment.Development]: development,
    [Environment.Staging]: staging,
    [Environment.Production]: production,
    [Environment.BonlivaInternal]: bonlivaInternal,
  }

  const currentConfig = configs[env]

  return {
    ...defaults,
    ...currentConfig,
  }
}

export const config = getConfig()
