import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Title = styled.div`
  margin-bottom: 20px;
`

export const PrimaryButton = styled.button`
  width: 100%;
  padding: 12px 6px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: #ff867b;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
`

export const SecondaryButton = styled.button`
  width: 100%;
  padding: 12px 6px;
  cursor: pointer;
  border: 1px solid #ff867b;
  background-color: white;
  color: #ff867b;
  border-radius: 4px;
  font-size: 16px;
`
