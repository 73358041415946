import { useAuth } from '@bonliva-auth/context'
import { useUnloadAll } from './useUnloadAll'

const useCleanLogout = () => {
  const auth = useAuth()
  const unloadAll = useUnloadAll()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cleanLogout = (unsubscribeNotifications = true) => {
    auth.logout()
    unloadAll()
  }

  return cleanLogout
}

export default useCleanLogout
