import React from 'react'
import { Paper } from '@material-ui/core'
import { Dashboard } from '../components/Dashboard/Dashboard'

const FeedbackKpis = () => {
  return (
    <>
      <div style={{ height: '20px' }} />
      <Paper style={{ padding: 16 }} elevation={2}>
        <Dashboard path="v1/admin/booking-feedbacks/kpis" />
      </Paper>
    </>
  )
}

export default FeedbackKpis
