import styled from 'styled-components'

export const CardContainer = styled.div`
  border: 1px solid gray;
  display: flex;
  flex-direction: column;
  width: 260px;
  padding: 18px;
  border-radius: 8px;
  height: fit-content;
`

export const Label = styled.div`
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 12px;
`

export const Data = styled.div`
  font-weight: 500;
  font-size: 48px;
  line-height: 57px;
  margin-bottom: 18px;
`

export const PercentWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ResultPercent = styled.div<{ positive: boolean }>`
  padding: 4px;
  margin-right: 8px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  color: ${({ positive }) => (positive ? '#4CD964' : '#B5322A')};
  background-color: ${({ positive }) => (positive ? '#32a85225' : '#b5322a25')};

  :before {
    content: ${({ positive }) => (positive ? '"+"' : '"-"')};
  }
`

export const SinceLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
`
