import { ActionMap } from '@bonliva-core/store'
import { Config } from '@bonliva-core/config'

export type State = Config

export enum Types {
  SET_CONFIG = 'SET_CONFIG',
  RESET_CONFIG = 'RESET_CONFIG',
}

export type Payload = {
  [Types.SET_CONFIG]: Config
  [Types.RESET_CONFIG]: undefined
}

export type Action = ActionMap<Payload>
