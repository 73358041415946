export const getLabel = (slug: string) => {
  switch (slug) {
    case 'rating':
      return 'Rating av videosamtal'
    case 'improvements':
      return 'Förbättringsförslag'
    case 'whats-good':
      return 'Vad var bra?'
    case 'new-bookings':
      return 'Nya bokningar'
    case 'bookings-by-treatment':
      return 'Bokningar per behandlingsområde'
    case 'new-users':
      return 'Nya användare'
    case 'new-treatment-plans':
      return 'Nya behandlingsplaner'
    case 'completed-bookings':
      return 'Genomförda bokningar'
    case 'cancelled-bookings':
      return 'Avbokade bokningar'
    case 'total-revenue':
      return 'Totala intäkter'
    default:
      return ''
  }
}
