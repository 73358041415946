import {
  ActionMap,
  ResponseError,
  RootCreatorPayload,
} from '@bonliva-core/store'
import { IFavoriteMedia } from '../types'

export type State = {
  hasLoaded: boolean
  isLoading: boolean
  page: number
  hasMore: boolean
  hasError?: ResponseError<IFavoriteMedia>
  data: IFavoriteMedia[]
  count: number
}

export enum Types {
  DELETE_FAVORITE = 'DELETE_FAVORITE',
  CREATE_FAVORITE = 'CREATE_FAVORITE',
}

export type Payload = RootCreatorPayload<IFavoriteMedia[]> & {
  [Types.DELETE_FAVORITE]: string
  [Types.CREATE_FAVORITE]: IFavoriteMedia
}

export type Action = ActionMap<Payload>
