import {
  ActionMap,
  ResponseError,
  RootCreatorPayload,
} from '@bonliva-core/store'
import { IAssignment } from '../types'

export type State = {
  hasLoaded: boolean
  isLoading: boolean
  page: number
  hasMore: boolean
  count: number
  newCount: number
  hasError?: ResponseError<IAssignment>
  data: IAssignment[]
}

export enum Types {
  SET_NEW_ASSIGNMENTS_COUNT = 'SET_NEW_ASSIGNMENTS_COUNT',
  SET_ASSIGNMENT = 'SET_ASSIGNMENT',
}

export type Payload = RootCreatorPayload<IAssignment[]> & {
  [Types.SET_NEW_ASSIGNMENTS_COUNT]: number
  [Types.SET_ASSIGNMENT]: IAssignment
}

export type Action = ActionMap<Payload>
