// Alter element in array of objects
export const alterArrayElement = <T, G extends T>(
  arr: T[],
  obj: G | string,
  key: keyof T,
  callback: (item: G) => Partial<T>
) =>
  arr.map((i) =>
    i[key] === (typeof obj === 'string' ? obj : obj[key])
      ? { ...i, ...callback(i as G) }
      : i
  )
