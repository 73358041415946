import { ResponseError, RootCreatorTypes } from './types'

export const unload = () => ({
  type: RootCreatorTypes.UNLOAD as const,
})

export const pending = () => ({
  type: RootCreatorTypes.PENDING as const,
})

export const refresh = () => ({
  type: RootCreatorTypes.REFRESH as const,
})

export const success = <T>(payload: T) => ({
  type: RootCreatorTypes.SUCCESS as const,
  payload,
})

export const hasMore = (payload: number) => ({
  type: RootCreatorTypes.HAS_MORE as const,
  payload,
})

export const fail = (error: unknown) => ({
  type: RootCreatorTypes.FAIL as const,
  payload: error as ResponseError,
})
