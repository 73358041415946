import { useAuth } from '@bonliva-auth/context'
import config from '@bonliva-core/config'
import jsonServerProvider from 'ra-data-json-server'
import { DataProvider, fetchUtils, Options } from 'react-admin'

export const useDataProvider = () => {
  const { getAccessToken } = useAuth()

  const httpClient = async (url: string, options: Options = {}) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
    }

    ;(options.headers as Headers).set(
      'Authorization',
      `Bearer ${await getAccessToken()}`
    )

    return fetchUtils.fetchJson(url, options)
  }

  const baseUrl = `${config.API_URL}v1/admin`

  const dataProvider = jsonServerProvider(baseUrl, httpClient)

  return {
    ...dataProvider,
    create: (resource, params) => {
      if (resource !== 'files') {
        return dataProvider.create(resource, params)
      }

      const formData = new FormData()
      formData.append('file', params.data.file.rawFile)

      return httpClient(`${baseUrl}/${resource}`, {
        method: 'POST',
        body: formData,
      }).then(({ json }) => ({ ...params.data, id: json.id }))
    },
  } as DataProvider
}
