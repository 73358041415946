import { AxiosInstance } from 'axios'

export type RefreshTokensResponse = {
  tokens: {
    accessToken: string
    refreshToken: string
  }
}

export const refreshTokens = (client: AxiosInstance, refreshToken: string) => {
  return client.post<RefreshTokensResponse>(
    '/v2/authentication/tokens/refresh',
    {
      refreshToken,
    }
  )
}
