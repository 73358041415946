import React, { useEffect } from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import {
  List,
  TextField,
  Datagrid,
  Show,
  TabbedShowLayout,
  Tab,
  ListButton,
  TopToolbar,
  ReferenceField,
  DateField,
  FunctionField,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin'
import { IBookingFeedback } from '@bonliva-traits/api/types'
import { useParams } from 'react-router-dom'
import useApiState from '@bonliva-traits/hooks/useApiState'

const feedbackFilters = [
  <SelectInput
    key="feedback-filter-resolved"
    source="_isResolved"
    label=""
    emptyText={'Ej hanterad feedback'}
    emptyValue={'false'}
    choices={[{ id: 'true', name: 'Hanterad feedback' }]}
    alwaysOn
  />,
  <ReferenceInput
    key="feedback-filter-careProvider"
    source="_careProviderId"
    label="Vårdgivare"
    reference="care-providers"
  >
    <AutocompleteInput
      source="name"
      optionText="name"
      filterToQuery={(searchText) => ({ name: `ilike:${searchText}` })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="feedback-filter-treater"
    source="_treaterId"
    label="Psykolog"
    reference="treaters"
  >
    <AutocompleteInput
      source="name"
      optionText="name"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
]

export const FeedbackList = () => (
  <List
    filters={feedbackFilters}
    filterDefaultValues={{ _isResolved: 'false' }}
    sort={{ field: 'bf.createdAt', order: 'DESC' }}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <ReferenceField
        label="Patient"
        source="bookingId"
        reference="bookings"
        sortable={false}
      >
        <ReferenceField
          source="patientId"
          reference="patients"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <DateField
        label="Datum"
        source="createdAt"
        sortable={true}
        sortBy={'bf.createdAt'}
      />
      <ReferenceField
        label="Inriktning"
        source="bookingId"
        reference="bookings"
        sortable={false}
      >
        <ReferenceField source="treatmentId" reference="treatment-categories">
          <TextField source="category" />
        </ReferenceField>
      </ReferenceField>
      <ReferenceField
        label="Psykolog"
        source="bookingId"
        reference="bookings"
        sortable={false}
      >
        <ReferenceField
          source="treaterId"
          reference="treaters"
          sortable={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </ReferenceField>
      <FunctionField
        label="Rating"
        source="rating"
        sortable={false}
        render={(record: IBookingFeedback) =>
          record.rating < 2
            ? `${record.rating} stjärna`
            : `${record.rating} stjärnor`
        }
      />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomShowbutton />
      </div>
    </Datagrid>
  </List>
)

export const FeedbackShow = () => {
  const { id } = useParams()
  const bookingFeedback = useApiState<IBookingFeedback>(
    `/v1/admin/booking-feedbacks/${id}`
  )

  const getBookingFeedbackHandler = async () => {
    bookingFeedback.get()
  }

  useEffect(() => {
    getBookingFeedbackHandler()
  }, [])

  const handleResolve = async () => {
    try {
      if (!bookingFeedback.data?.resolvedAt) {
        await bookingFeedback.put({}, `resolve`)
      } else {
        await bookingFeedback.put({}, `unresolve`)
      }
    } catch (error) {
      throw new Error('Something went wrong')
    }
  }

  return (
    <Show
      actions={
        <TopToolbar>
          <ListButton label="Gå tillbaka" icon={<></>} />
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <Tab
          label="Feedbackinformation"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <ReferenceField
            label="Patient"
            source="bookingId"
            reference="bookings"
            sortable={false}
          >
            <ReferenceField
              source="patientId"
              reference="patients"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          <DateField label="Datum" source="createdAt" sortable={false} />
          <ReferenceField
            label="Inriktning"
            source="bookingId"
            reference="bookings"
            sortable={false}
          >
            <ReferenceField
              source="treatmentId"
              reference="treatment-categories"
            >
              <TextField source="category" />
            </ReferenceField>
          </ReferenceField>
          <ReferenceField
            label="Psykolog"
            source="bookingId"
            reference="bookings"
            sortable={false}
          >
            <ReferenceField
              source="treaterId"
              reference="treaters"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
          </ReferenceField>
          <FunctionField
            label="Rating"
            source="rating"
            render={(record: IBookingFeedback) =>
              record.rating < 2
                ? `${record.rating} stjärna`
                : `${record.rating} stjärnor`
            }
          />
          <FunctionField
            label="Godkänt att bli kontaktad"
            source="canContactAcceptedAt"
            render={(record: IBookingFeedback) =>
              record.canContactAcceptedAt ? 'Ja' : 'Nej'
            }
          />
          <ReferenceField
            label="Telefonnummer"
            source="bookingId"
            reference="bookings"
            sortable={false}
          >
            <ReferenceField
              source="patientId"
              reference="patients"
              sortable={false}
            >
              <TextField source="phone" />
            </ReferenceField>
          </ReferenceField>
          <ReferenceField
            label="Email"
            source="bookingId"
            reference="bookings"
            sortable={false}
          >
            <ReferenceField
              source="patientId"
              reference="patients"
              sortable={false}
            >
              <TextField source="email" />
            </ReferenceField>
          </ReferenceField>

          <FunctionField
            label="Feedback"
            source="answers"
            render={(record: IBookingFeedback) => {
              const groupedAnswers =
                record.answers?.reduce((carry, item) => {
                  const question: string =
                    item.bookingFeedbackQuestion?.question || ''

                  if (question in carry) {
                    carry[question].push(item.answer)
                  } else {
                    carry[question] = [item.answer]
                  }

                  return carry
                }, {} as Record<string, string[]>) || {}
              return (
                <div style={{ width: '100%' }}>
                  {Object.entries(groupedAnswers).map(([question, answers]) => (
                    <div key={question} style={{ marginBottom: '12px' }}>
                      <div>{question}</div>
                      {answers.map((a) => (
                        <div
                          key={a}
                          style={{
                            display: 'inline-block',
                            marginRight: '8px',
                            marginTop: '4px',
                            borderRadius: '8px',
                            border: '1px solid',
                            color: 'white',
                            borderColor: defaultTheme.colors.admin.primary,
                            backgroundColor:
                              defaultTheme.colors.admin.primaryLight,
                            padding: '8px',
                            width: 'fit-content',
                          }}
                        >
                          {a}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )
            }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <button
              style={{
                cursor: 'pointer',
                width: 'fit-content',
                borderRadius: '8px',
                border: '1px solid',
                padding: '12px',
                color: bookingFeedback.data?.resolvedAt
                  ? defaultTheme.colors.admin.primary
                  : 'white',
                backgroundColor: bookingFeedback.data?.resolvedAt
                  ? 'white'
                  : defaultTheme.colors.admin.primary,
              }}
              onClick={handleResolve}
            >
              {bookingFeedback.data?.resolvedAt
                ? 'Markera som ej hanterad'
                : 'Markera som hanterad'}
            </button>
          </div>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}
