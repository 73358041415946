import styled from 'styled-components'

export const AnalysisSummaryContainer = styled.div`
  display: flex;
`

export const CardContainer = styled.div`
  height: 100%;
  max-width: 600px;
  display: flex;
  flex-wrap: wrap;
  gap: 34px;
  margin-right: 34px;
`

export const GraphContainer = styled.div`
  width: 800px;
`
