import CustomDeletebutton from '@bonliva-admin/custom-deletebutton'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  FileInput,
  ImageField,
  TopToolbar,
  ListButton,
  ReferenceField,
} from 'react-admin'

export const FileList = () => (
  <List>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <TextField label="Namn" source="name" sortable={false} />
      <ReferenceField
        label="Uppladdad av"
        source="uploadedById"
        reference="treaters"
      >
        <TextField source="name" sortable={false} />
      </ReferenceField>
      <ImageField
        label="Bild"
        source="url"
        sortable={false}
        sx={{
          '& .RaImageField-image': {
            height: '50px',
            width: '50px',
            borderRadius: '4px',
            objectFit: 'cover',
          },
        }}
      />

      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomDeletebutton />
      </div>
    </Datagrid>
  </List>
)

export const FileCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <FileInput source="file" />
    </SimpleForm>
  </Create>
)
