import React from 'react'
import { Layout, LayoutProps } from 'react-admin'
import AdminMenu from '@bonliva-admin/admin-menu'
import customAppbar from '@bonliva-admin/custom-appbar'

const AdminLayout = (props: LayoutProps) => (
  <Layout {...props} menu={AdminMenu} appBar={customAppbar} />
)

export default AdminLayout
