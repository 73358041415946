import { useAuth } from '@bonliva-auth/context'
import useCleanLogout from '@bonliva-auth/helpers/useCleanLogout'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import { useConfig } from '@bonliva-traits/config'

export type { AxiosError, AxiosResponse }

export const useApi = () => {
  const { API_URL } = useConfig()
  const { getAccessToken } = useAuth()
  const cleanLogout = useCleanLogout()

  const client = Axios.create({
    baseURL: API_URL,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })

  client.interceptors.request.use(
    async (config) => {
      const token = await getAccessToken()
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (err) => Promise.reject(err)
  )

  client.interceptors.response.use(
    (response) => response,
    (err) => {
      if (err.response.status === 401) cleanLogout(false)
      return Promise.reject(err)
    }
  )

  return client
}

export const useCms = () => {
  const { getAccessToken } = useAuth()
  const { CMS_API_URL } = useConfig()

  const client = Axios.create({
    baseURL: CMS_API_URL,
    headers: {
      Authorization: `Bearer ${getAccessToken()}`,
    },
  })
  client.interceptors.request.use(
    async (config) => {
      const token = await getAccessToken()
      config.headers.Authorization = `Bearer ${token}`
      return config
    },
    (err) => Promise.reject(err)
  )

  return client
}
