import type { AuthProvider } from 'react-admin'
import { AuthContext } from '@bonliva-auth/context'
import { Status } from '@bonliva-auth/context/context'

export const authProvider = (auth: AuthContext): AuthProvider => ({
  // called when the user attempts to log in
  login: () => {
    return Promise.resolve()
  },
  // called when the user clicks on the logout button
  logout: () => {
    return auth.status === Status.Authenticated
      ? Promise.resolve(auth.logout())
      : Promise.resolve()
  },
  // called when the API returns an error
  checkError: () => {
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return auth.status === Status.Authenticated
      ? Promise.resolve()
      : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => {
    return Promise.resolve()
  },
})
