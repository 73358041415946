import React from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import { DeleteWithConfirmButton, useRecordContext } from 'react-admin'

const CustomDeleteButton = () => {
  const record = useRecordContext()
  return (
    <DeleteWithConfirmButton
      confirmTitle={`Radera ${record.name}`}
      confirmContent={`Du kan inte ångra den här åtgärden. Är du säker att du vill ta bort ${record.name}`}
      label="Radera"
      sx={{
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: defaultTheme.colors.admin.primary,
        fontWeight: 'bold',
        padding: defaultTheme.margin['3xs'],
        margin: '0 6px',
        ':hover': {
          backgroundColor: 'transparent',
        },
        ':only-child': {
          margin: '0 0',
        },
      }}
    />
  )
}

export default CustomDeleteButton
