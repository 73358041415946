import { UserType } from '@bonliva-auth/api'
import { CancelResponse } from '@bonliva-auth/api/requests/cancelAuthentication'
import { QrCodeResponse } from '@bonliva-auth/api/requests/generateQrCode'
import { StartAuthenticationResponse } from '@bonliva-auth/api/requests/startAuthentication'
import { VerifyResponse } from '@bonliva-auth/api/requests/verifyAuthentication'
import { AxiosPromise } from 'axios'
import React from 'react'

export enum Status {
  Authenticating = 'authenticating',
  NotAuthenticated = 'not-authenticated',
  Authenticated = 'authenticated',
}

export type AuthContext = {
  status: Status
  isLoading: boolean
  messageCode?: string
  login: (
    userType: UserType,
    demoMode?: boolean
  ) => AxiosPromise<StartAuthenticationResponse>
  logout: () => Promise<void>
  verify: () => Promise<VerifyResponse>
  cancel: () => AxiosPromise<CancelResponse>
  qrCode: () => AxiosPromise<QrCodeResponse>
  getBankIDLink: (redirectTo: string, autoStartToken?: string) => string
  getAccessToken: () => Promise<string>
  getAutoStartToken: () => Promise<string>
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.')
}

export const AuthContext = React.createContext<AuthContext>({
  status: Status.NotAuthenticated,
  isLoading: false,
  login: stub,
  logout: stub,
  verify: stub,
  cancel: stub,
  qrCode: stub,
  getBankIDLink: stub,
  getAccessToken: stub,
  getAutoStartToken: stub,
})

export const useAuth = () => React.useContext(AuthContext)
