export const calculateAge = (personalNumber: string) => {
  // Extract the year, month, and day from the personal number
  const year = Number(personalNumber.substring(0, 4))
  const month = Number(personalNumber.substring(4, 6))
  const day = Number(personalNumber.substring(6, 8))

  // Calculate the person's age in years and months
  const today = new Date()
  const birthDate = new Date(year, month - 1, day)
  let ageInMonths = (today.getFullYear() - birthDate.getFullYear()) * 12
  ageInMonths -= birthDate.getMonth()
  ageInMonths += today.getMonth()
  if (today.getDate() < birthDate.getDate()) {
    ageInMonths--
  }

  const ageInYears = Math.floor(ageInMonths / 12)
  const remainingMonths = ageInMonths % 12

  // Return the person's age as a string
  if (remainingMonths === 0) return `${ageInYears} years`
  return `${ageInYears} år och ${remainingMonths} månader`
}
