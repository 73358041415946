import React from 'react'
import AppComp from './app'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { latestTheme } from './theme/theme'
import { AuthProvider } from '@bonliva-auth/context'
import { PlatformType } from '@bonliva-auth/api'
import { defaultTheme } from '@bonliva-ui/core/theme'
import { GlobalStyles } from '@bonliva-ui/core/global'

const App: React.FC = () => {
  return (
    <AuthProvider platformType={PlatformType.Web}>
      <BrowserRouter>
        <StyledThemeProvider theme={defaultTheme}>
          <ThemeProvider theme={latestTheme}>
            <GlobalStyles />
            <AppComp />
          </ThemeProvider>
        </StyledThemeProvider>
      </BrowserRouter>
    </AuthProvider>
  )
}

export default App
