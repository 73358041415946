import { createStore, RootCreatorTypes } from '@bonliva-core/store'
import { alterArrayElement, unique } from '@bonliva-traits/utils'
import { Action, State, Types } from './types'

const initialState: State = {
  hasLoaded: false,
  isLoading: false,
  page: 0,
  hasMore: true,
  hasError: undefined,
  unreadCount: 0,
  count: 0,
  data: [],
}

const store = createStore<State, Action>({
  name: 'notifications',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case RootCreatorTypes.UNLOAD:
        return initialState

      case RootCreatorTypes.REFRESH:
        return {
          ...state,
          isLoading: true,
          hasMore: true,
          hasError: undefined,
          page: 0,
        }

      case RootCreatorTypes.PENDING:
        return {
          ...state,
          isLoading: true,
          hasError: undefined,
        }

      case RootCreatorTypes.SUCCESS:
        return {
          ...state,
          hasLoaded: true,
          isLoading: false,
          page: state.hasMore ? state.page + 1 : state.page,
          data:
            state.page === 0
              ? action.payload
              : unique(state.data, action.payload)('id'),
        }

      case Types.SET_NOTIFICATION:
        return {
          ...state,
          isLoading: false,
          data: alterArrayElement(
            state.data,
            action.payload,
            'id',
            () => action.payload
          ),
        }

      case Types.REMOVE_NOTIFICATION:
        return {
          ...state,
          data: state.data.filter((item) => item.id !== action.payload),
        }

      case Types.SET_UNREAD_COUNT:
        return {
          ...state,
          unreadCount: action.payload,
        }

      case RootCreatorTypes.HAS_MORE:
        return {
          ...state,
          count: action.payload,
          hasMore: action.payload - state.data.length > 0,
        }

      case RootCreatorTypes.FAIL:
        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          hasError: action.payload,
        }

      default:
        return state
    }
  },
})

export default store
