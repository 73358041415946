import React from 'react'
import { Resource, Admin, TranslationMessages, CustomRoutes } from 'react-admin'
import {
  BookingList,
  BookingCreate,
  BookingShow,
  BookingEdit,
} from './resources/bookings'
import {
  PatientList,
  PatientCreate,
  PatientShow,
  PatientEdit,
} from './resources/patients'
import {
  TreaterList,
  TreaterCreate,
  TreaterEdit,
  TreaterShow,
} from './resources/treaters'
import {
  TreatmentCategoryList,
  TreatmentCategoryCreate,
  TreatmentCategoryEdit,
} from './resources/treatment-categories'
import { authProvider } from './providers/authProvider'
import { LoginPage } from './pages/LoginPage'
import { useDataProvider } from './providers/dataProvider'
import { FileCreate, FileList } from './resources/files'
import {
  SpecialistCompetenceCreate,
  SpecialistCompetenceEdit,
  SpecialistCompetenceList,
} from './resources/specialist-competence'
import AdminLayout from '@bonliva-admin/admin-layout'
import { legacyTheme } from './theme/theme'
import {
  ReferralPlanCreate,
  ReferralPlanEdit,
  ReferralPlanList,
  ReferralPlanShow,
} from './resources/referral-plans'
import { useAuth } from '@bonliva-auth/context'
import {
  AdminCreate,
  AdminEdit,
  AdminList,
  AdminShow,
} from './resources/admins'
import {
  CareProviderCreate,
  CareProviderEdit,
  CareProviderList,
  CareProviderShow,
} from './resources/care-providers'
import { Route } from 'react-router-dom'
import { FortnoxPage } from './pages/FortnoxPage'
import {
  TreatmentPlanList,
  TreatmentPlanShow,
} from './resources/treatment-plans'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import swedishMessages from '@kolben/ra-language-swedish'
import { FeedbackList, FeedbackShow } from './resources/feedback'
import FeedbackKpis from './resources/CustomRoutes/FeedbackKpis/FeedbackKpis'
import AnalysisSummary from './resources/CustomRoutes/AnalysisSummary/AnalysisSummary'
import AnalysisKpis from './resources/CustomRoutes/AnalysisKpis/AnalysisKpis'
import {
  SpecialistCompetenceTreatmentCategoryCreate,
  SpecialistCompetenceTreatmentCategoryEdit,
  SpecialistCompetenceTreatmentCategoryList,
} from './resources/specialist-competence-treatment-category'

const App: React.FC = () => {
  const auth = useAuth()
  const dataProvider = useDataProvider()

  const messages: { [key: string]: TranslationMessages } = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    sv: swedishMessages,
  }

  const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'sv')

  return (
    <Admin
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={authProvider(auth)}
      loginPage={LoginPage}
      disableTelemetry={true}
      layout={AdminLayout}
      theme={legacyTheme}
      requireAuth={true}
    >
      <Resource
        name="bookings"
        options={{ label: 'Bokningar' }}
        recordRepresentation={(record) => record.title}
        list={BookingList}
        create={BookingCreate}
        show={BookingShow}
        edit={BookingEdit}
      />
      <Resource
        name="patients"
        options={{ label: 'Patienter' }}
        recordRepresentation={(record) => record.title}
        list={PatientList}
        create={PatientCreate}
        show={PatientShow}
        edit={PatientEdit}
      />
      <Resource
        name="admins"
        options={{ label: 'Admins' }}
        recordRepresentation={(record) => record.title}
        list={AdminList}
        create={AdminCreate}
        show={AdminShow}
        edit={AdminEdit}
      />
      <Resource
        name="care-providers"
        options={{ label: 'Vårdgivare' }}
        recordRepresentation={(record) => record.title}
        list={CareProviderList}
        create={CareProviderCreate}
        show={CareProviderShow}
        edit={CareProviderEdit}
      />
      <Resource
        name="referral-plans"
        options={{ label: 'Remisser' }}
        recordRepresentation={(record) => record.title}
        list={ReferralPlanList}
        create={ReferralPlanCreate}
        show={ReferralPlanShow}
        edit={ReferralPlanEdit}
      />
      <Resource
        name="treatment-plans"
        options={{ label: 'Behandlingsplaner' }}
        recordRepresentation={(record) => record.title}
        list={TreatmentPlanList}
        show={TreatmentPlanShow}
      />
      <Resource
        name="treaters"
        options={{ label: 'Psykologer' }}
        recordRepresentation={(record) => record.title}
        list={TreaterList}
        create={TreaterCreate}
        edit={TreaterEdit}
        show={TreaterShow}
      />
      <Resource
        name="specialist-competences"
        recordRepresentation={(record) => record.title}
        options={{ label: 'Specialistkompetenser' }}
        list={SpecialistCompetenceList}
        create={SpecialistCompetenceCreate}
        edit={SpecialistCompetenceEdit}
      />
      <Resource
        name="treatment-categories"
        recordRepresentation={(record) => record.title}
        options={{ label: 'Behandlingsområden' }}
        list={TreatmentCategoryList}
        create={TreatmentCategoryCreate}
        edit={TreatmentCategoryEdit}
      />
      <Resource
        name="specialist-competence-treatment-category"
        recordRepresentation={(record) => record.title}
        options={{ label: 'Specialist Competence Treatment Category' }}
        list={SpecialistCompetenceTreatmentCategoryList}
        create={SpecialistCompetenceTreatmentCategoryCreate}
        edit={SpecialistCompetenceTreatmentCategoryEdit}
      />
      <Resource
        name="files"
        recordRepresentation={(record) => record.title}
        options={{ label: 'Filer' }}
        list={FileList}
        create={FileCreate}
      />
      <Resource
        name="booking-feedbacks"
        recordRepresentation={(record) => record.title}
        options={{ label: 'Hanterad feedback' }}
        list={FeedbackList}
        show={FeedbackShow}
      />
      <CustomRoutes>
        <Route path="/feedback-kpis" element={<FeedbackKpis />} />
        <Route path="/analysis-summary" element={<AnalysisSummary />} />
        <Route path="/analysis-kpis" element={<AnalysisKpis />} />
        <Route path="/fortnox" element={<FortnoxPage />} />
      </CustomRoutes>
    </Admin>
  )
}

export default App
