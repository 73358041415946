export type { AnyAction, Reducer, Middleware } from 'redux'

// Remove array from type
export type UnArray<T> = T extends Array<infer U> ? U : T

export type FieldsValue = {
  message: string
  value: unknown
}

export type ErrorFields<T> = {
  [K in keyof T]: FieldsValue
}

export type ResponseError<T = never> = {
  error_id: string
  fields: ErrorFields<T>
  message: string
  status: number
  request_id: string
}

export enum RootCreatorTypes {
  UNLOAD = 'UNLOAD',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  REFRESH = 'REFRESH',
  HAS_MORE = 'HAS_MORE',
  FAIL = 'FAIL',
}

export interface RootCreatorPayload<T = never> {
  [RootCreatorTypes.UNLOAD]: undefined
  [RootCreatorTypes.PENDING]: undefined
  [RootCreatorTypes.REFRESH]: undefined
  [RootCreatorTypes.SUCCESS]: T
  [RootCreatorTypes.HAS_MORE]: number
  [RootCreatorTypes.FAIL]: ResponseError<UnArray<T>>
}

export type Actions<M extends { [index: string]: unknown }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key
      }
    : {
        type: Key
        payload: M[Key]
      }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionMap<T extends { [index: string]: any }> =
  Actions<T>[keyof Actions<T>]
