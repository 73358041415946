import { AuthContext, Status } from './context'
import React, { PropsWithChildren, useCallback, useEffect } from 'react'
import {
  PlatformType,
  startAuthentication,
  UserType,
  verifyAuthentication,
  Status as VerifyStatus,
  cancelAuthentication,
  generateQrCode,
  refreshTokens as refreshTokensRequest,
} from '@bonliva-auth/api'
import decodeToken from 'jwt-decode'
import { isPast } from 'date-fns'
import { useDebouncedCallback } from 'use-debounce'
import { VerifyResponse } from '@bonliva-auth/api/requests/verifyAuthentication'
import * as Axios from 'axios'
import { useAuthApi } from '@bonliva-auth/api/useAuthApi'

type AuthProviderState = {
  status: Status
  isLoading: boolean
  orderRef?: string
  accessToken?: string
  autoStartToken?: string
  messageCode?: string
}

type StartAuthAction = {
  type: 'START_AUTH'
  orderRef: string
  autoStartToken: string
}

type UpdateMessageCodeAction = {
  type: 'UPDATE_MESSAGE_CODE'
  messageCode: string
}

type AuthenticationFulfilledAction = {
  type: 'AUTHENTICATION_FULFILLED'
  accessToken: string
}

type AuthenticationRejectedAction = {
  type: 'AUTHENTICATION_REJECTED'
  messageCode: string
}

type LogoutAction = {
  type: 'LOGOUT'
}

type SetAccessTokenAction = {
  type: 'SET_ACCESS_TOKEN'
  accessToken: string
}

type TooYoungAction = {
  type: 'SET_TOO_YOUNG'
}
type InactivatedUserAction = {
  type: 'USER_INACTIVATED'
}

type Actions =
  | StartAuthAction
  | UpdateMessageCodeAction
  | AuthenticationFulfilledAction
  | AuthenticationRejectedAction
  | LogoutAction
  | SetAccessTokenAction
  | TooYoungAction
  | InactivatedUserAction

type Props = {
  platformType: PlatformType
  getRefreshToken: () => Promise<string | null>
  setRefreshToken: (token: string) => Promise<void>
  initialState: AuthProviderState
}

type Jwt = {
  exp: number
  iat: number
}

const isExpired = (jwt: string) => {
  try {
    const decoded = decodeToken<Jwt>(jwt)
    return isPast(new Date(decoded.exp * 1000))
  } catch (error) {
    const err = error as Error
    const ignoreMessage = 'Invalid token specified: e is undefined'

    if (err?.message === ignoreMessage) return false
    else throw error
  }
}

export const BaseAuthProvider: React.FC<PropsWithChildren<Props>> = (props) => {
  const client = useAuthApi()

  const [state, dispatch] = React.useReducer(
    (state: AuthProviderState, action: Actions) => {
      switch (action.type) {
        case 'START_AUTH':
          return {
            ...state,
            status: Status.Authenticating,
            isLoading: true,
            orderRef: action.orderRef,
            autoStartToken: action.autoStartToken,
          }

        case 'UPDATE_MESSAGE_CODE':
          return {
            ...state,
            messageCode: action.messageCode,
          }
        case 'AUTHENTICATION_FULFILLED':
          return {
            ...state,
            status: Status.Authenticated,
            isLoading: false,
            accessToken: action.accessToken,
          }
        case 'AUTHENTICATION_REJECTED':
          return {
            ...state,
            status: Status.NotAuthenticated,
            isLoading: false,
            messageCode: action.messageCode,
          }
        case 'LOGOUT':
          return {
            status: Status.NotAuthenticated,
            isLoading: false,
          }
        case 'SET_ACCESS_TOKEN':
          return {
            ...state,
            accessToken: action.accessToken,
          }
        case 'SET_TOO_YOUNG':
          return {
            ...state,
            isLoading: false,
            status: Status.NotAuthenticated,
          }
        case 'USER_INACTIVATED':
          return {
            ...state,
            isLoading: false,
            status: Status.NotAuthenticated,
            messageCode: 'USERINACTIVATED',
          }
        default:
          return state
      }
    },
    props.initialState
  )

  const login = async (userType: UserType, demoMode?: boolean) => {
    const response = await startAuthentication(
      client,
      userType,
      props.platformType,
      demoMode
    )
    dispatch({
      type: 'START_AUTH',
      orderRef: response.data.orderRef,
      autoStartToken: response.data.autoStartToken,
    })
    return response
  }

  const verify = async () => {
    try {
      const response = await verifyAuthentication(client, state.orderRef || '')
      switch (response.data.status) {
        case VerifyStatus.Complete:
          dispatch({
            type: 'AUTHENTICATION_FULFILLED',
            accessToken: response.data.tokens.accessToken,
          })
          await props.setRefreshToken(response.data.tokens.refreshToken)
          break
        case VerifyStatus.Error:
        case VerifyStatus.Failed:
          dispatch({
            type: 'AUTHENTICATION_REJECTED',
            messageCode: response.data.messageCode,
          })
          break
        case VerifyStatus.Pending:
          dispatch({
            type: 'UPDATE_MESSAGE_CODE',
            messageCode: response.data.messageCode,
          })
          break
      }

      return response.data
    } catch (error) {
      if (Axios.isAxiosError(error)) {
        if (
          error.response?.data.message ===
          'Patient must be at least 18 years old'
        ) {
          dispatch({
            type: 'SET_TOO_YOUNG',
          })
          return {
            status: VerifyStatus.Error,
            messageCode: 'TOOYOUNG',
          } as VerifyResponse
        }
        if (error.response?.data.message === 'This user is inactivated') {
          dispatch({
            type: 'USER_INACTIVATED',
          })
          return {
            status: VerifyStatus.Error,
            messageCode: 'USERINACTIVATED',
          } as VerifyResponse
        }
        throw error
      }
      throw error
    }
  }

  const logout = async () => {
    await props.setRefreshToken('')
    dispatch({ type: 'LOGOUT' })
  }

  const cancel = async () => {
    const response = await cancelAuthentication(client, state.orderRef || '')
    dispatch({ type: 'LOGOUT' })
    return response
  }

  const qrCode = () => {
    return generateQrCode(client, state.orderRef || '')
  }

  const refreshTokens = useDebouncedCallback(
    async () => {
      const refreshToken = await props.getRefreshToken()

      if (!refreshToken) return

      try {
        const response = await refreshTokensRequest(client, refreshToken)

        dispatch({
          type: 'SET_ACCESS_TOKEN',
          accessToken: response.data.tokens.accessToken,
        })

        await props.setRefreshToken(response.data.tokens.refreshToken)

        return response
      } catch (error) {
        return
      }
    },
    2000,
    { leading: true, trailing: false }
  )

  const getAccessToken = useCallback(async () => {
    if (!state.accessToken || isExpired(state.accessToken || '')) {
      const response = await refreshTokens()

      return response?.data.tokens.accessToken || ''
    }

    return state.accessToken || ''
  }, [state.accessToken])

  const getAutoStartToken = useCallback(async () => {
    return state.autoStartToken || ''
  }, [state.autoStartToken])

  const getBankIDLink = (redirectTo: string, autoStartToken?: string) =>
    props.platformType === PlatformType.Web
      ? `bankid:///?autostarttoken=${
          autoStartToken || state.autoStartToken
        }&redirect=${redirectTo}`
      : `https://app.bankid.com/?autostarttoken=${
          autoStartToken || state.autoStartToken
        }&redirect=${redirectTo}`

  const getRefreshTokenHandler = useDebouncedCallback(
    async () => {
      const refreshToken = await props.getRefreshToken()

      if (!refreshToken || isExpired(refreshToken))
        return dispatch({ type: 'LOGOUT' })

      if (state.status !== Status.Authenticated && !state.isLoading) {
        dispatch({ type: 'START_AUTH', orderRef: '', autoStartToken: '' })
      }

      try {
        const res = await refreshTokens()

        dispatch({
          type: 'AUTHENTICATION_FULFILLED',
          accessToken: res?.data.tokens.accessToken || '',
        })
      } catch (error) {
        dispatch({ type: 'LOGOUT' })
      }
    },
    200,
    { leading: true, trailing: false }
  )

  useEffect(() => {
    getRefreshTokenHandler()
  }, [])

  /**
   * Check if the token is expired every 30 seconds and renew if needed
   */
  useEffect(() => {
    if (state.status === Status.Authenticated) {
      const interval = setInterval(() => {
        if (state.accessToken && isExpired(state.accessToken)) {
          refreshTokens()
        }
      }, 1000 * 30)

      return () => clearInterval(interval)
    }
  }, [state.accessToken])

  return (
    <AuthContext.Provider
      value={{
        status: state.status,
        isLoading: state.isLoading,
        messageCode: state.messageCode,
        login,
        logout,
        verify,
        cancel,
        qrCode,
        getBankIDLink,
        getAccessToken,
        getAutoStartToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}
