import config from '@bonliva-core/config'
import { createStore } from '@bonliva-core/store'
import { Action, State, Types } from './types'

const initialState: State = config

const store = createStore<State, Action>({
  name: 'developer',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case Types.SET_CONFIG:
        return {
          ...state,
          ...action.payload,
        }

      case Types.RESET_CONFIG:
        return config

      default:
        return state
    }
  },
})

export default store
