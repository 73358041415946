import React from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import {
  List,
  TextField,
  Datagrid,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  DateField,
  ReferenceField,
  SimpleForm,
  Create,
  TextInput,
  required,
  ListButton,
  TopToolbar,
  SearchInput,
  Edit,
  BooleanInput,
  FunctionField,
  minLength,
  regex,
} from 'react-admin'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import CustomEditbutton from '@bonliva-admin/custom-editbutton'
import { useParams } from 'react-router-dom'
import { useApi } from '@bonliva-traits/api'
import Spinner from '@bonliva-admin/spinner'
import { IPatient } from '@bonliva-traits/api/types'
import { calculateAge } from '../helpers/calculateAge'
import CustomShowButton from '@bonliva-admin/custom-showbutton/CustomShowButton'
import { validateSSN } from '../helpers/validateSSN'

const patientFilters = [
  <SearchInput key="patient-filter-patient" source="_search" alwaysOn />,
]

export const PatientList = () => (
  <List
    filters={patientFilters}
    sort={{ field: 'Patient.createdAt', order: 'ASC' }}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <TextField label="Namn" source="name" sortable={false} />
      <TextField
        label="Personnummer"
        source="personalNumber"
        sortable={false}
      />
      <TextField label="E-mail" source="email" sortable={false} />
      <TextField label="Telefonnummer" source="phone" sortable={false} />
      <DateField
        label="Registrerad"
        source="createdAt"
        sortable
        sortBy="Patient.createdAt"
      />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomEditbutton />
        <CustomShowbutton />
      </div>
    </Datagrid>
  </List>
)

export const PatientShow = () => {
  const { id } = useParams()
  const [loading, setLoading] = React.useState(false)
  const api = useApi()

  const syncWebdoc = async (patientId?: string) => {
    try {
      setLoading(true)
      if (!patientId) throw new Error('No valid patientId')
      await api.put(`/v1/admin/patients/webdoc/${patientId}`)
      setTimeout(() => {
        setLoading(false)
      }, 500)
    } catch (error) {
      throw new Error('Something went wrong')
    }
  }

  return (
    <Show
      actions={
        <TopToolbar>
          <ListButton label="Gå tillbaka" icon={<></>} />
        </TopToolbar>
      }
    >
      <TabbedShowLayout>
        <Tab
          label="Patientinformation"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <TextField source="name" label="Namn" />

          <FunctionField
            label="Ålder"
            render={(record: IPatient) => calculateAge(record.personalNumber)}
          />
          <TextField source="phone" label="Telefonnummer" />
          <TextField source="email" label="E-mail" />
          <TextField source="id" label="Id" />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '16px',
            }}
          >
            <button
              style={{
                width: '100px',
                height: '40px',
                borderRadius: '4px',
                backgroundColor: defaultTheme.colors.admin.primary,
                border: '1px solid transparent',
                padding: defaultTheme.margin['3xs'],
                color: 'white',
                cursor: 'pointer',
                marginBottom: '8px',
              }}
              disabled={!id}
              onClick={() => syncWebdoc(id)}
            >
              {loading ? <Spinner color="white" /> : 'Synk Webdoc'}
            </button>
            <span style={{ fontSize: '16px', fontStyle: 'italic' }}>
              Kom ihåg patienten måste skapas i webdoc innan du kan synka
            </span>
          </div>
        </Tab>
        <Tab
          label="Kommande bokningar"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <ReferenceManyField
            reference="bookings"
            target="patientId"
            label=""
            filter={{ startDate: `gte:${new Date().toISOString()}` }}
            sort={{ field: 'Booking.startDate', order: 'DESC' }}
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              rowStyle={() => {
                return { cursor: 'auto' }
              }}
            >
              <ReferenceField
                source="treaterId"
                reference="treaters"
                label="Psykolog"
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <DateField source="startDate" label="Datum" sortable={false} />
              <ReferenceField
                source="treaterId"
                reference="treaters"
                label="Psykolog"
              >
                <TextField source="name" />
              </ReferenceField>

              <ReferenceField
                source="treatmentId"
                reference="treatment-categories"
                label="Behandlingsområde"
                sortable={false}
              >
                <TextField source="category" />
              </ReferenceField>
              <TextField source="status" label="Status" sortable={false} />
              <CustomShowbutton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab
          label="Historik"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <ReferenceManyField
            reference="bookings"
            target="patientId"
            label=""
            filter={{ startDate: `lt:${new Date().toISOString()}` }}
            sort={{ field: 'Booking.startDate', order: 'DESC' }}
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              rowStyle={() => {
                return { cursor: 'auto' }
              }}
            >
              <ReferenceField
                source="treaterId"
                reference="treaters"
                label="Psykolog"
                sortable={false}
              >
                <TextField source="name" />
              </ReferenceField>

              <DateField source="startDate" label="Datum" sortable={false} />
              <ReferenceField
                source="treatmentId"
                reference="treatment-categories"
                label="Behandlingsområde"
                sortable={false}
              >
                <TextField source="category" sortable={false} />
              </ReferenceField>
              <TextField source="status" label="Status" sortable={false} />
              <CustomShowbutton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab
          label="Remisser"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <ReferenceManyField
            reference="referral-plans"
            target="patientId"
            label=""
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              rowStyle={() => {
                return { cursor: 'auto' }
              }}
            >
              <TextField
                label="Berättigade möten totalt"
                source="numberOfAppointments"
              />
              <DateField
                label="Första samtal måste genomföras senast"
                source="firstAppointmentExpiryDate"
                sortable
              />

              <DateField
                label="Remiss slutar vara giltigt från och med"
                source="expiryDate"
                sortable
              />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CustomShowButton />
              </div>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab
          label="Behandlingsplaner"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <ReferenceManyField
            reference="treatment-plans"
            target="patientId"
            label=""
          >
            <Datagrid
              bulkActionButtons={false}
              rowClick={false}
              rowStyle={() => {
                return { cursor: 'auto' }
              }}
            >
              <ReferenceField
                label="Patient"
                reference="patients"
                source="patientId"
                sortable={false}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Psykolog"
                reference="treaters"
                source="treaterId"
                sortable={false}
                link="show"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField label="Status" source="status" />
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <CustomShowbutton />
              </div>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab
          label="Avancerad"
          sx={{
            color: defaultTheme.colors.admin.darkGray,
          }}
        >
          <TextField source="id" />
          <TextField source="personalNumber" />
          <TextField source="type" />
          <TextField source="name" />
          <TextField source="email" />
          <TextField source="phone" />
          <TextField source="termsAndConditionsAccepted" />
          <TextField source="privacyPolicyAccepted" />
          <TextField source="emailPreferences" />
          <TextField source="createdAt" />
          <TextField source="stripeCustomerId" />
          <TextField source="webdocId" />
          <TextField source="patientRecordAcceptedAt" />
          <TextField source="marketingAccepted" />
          <TextField source="bookings" />
          <TextField source="deletedAt" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export const PatientCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput
        label="Personnummer"
        source="personalNumber"
        validate={[
          required('Du måste fylla i personnummer'),
          minLength(12, 'Personnumret måste vara 12 siffror'),
          regex(/^[0-9]*$/, 'Personnumret får bara innehålla siffror'),
          validateSSN,
        ]}
      />
      <TextInput label="Namn" source="name" validate={[required()]} />
      <TextInput label="E-mail" source="email" />
      <TextInput label="Telefonnummer" source="phone" />
    </SimpleForm>
  </Create>
)

export const PatientEdit = () => (
  <Edit
    transform={(data) => {
      return {
        ...data,
        deletedAt: data.deletedAt ? new Date() : null,
      }
    }}
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput
        label="Personnummer"
        source="personalNumber"
        validate={[required()]}
        disabled={true}
      />
      <TextInput label="Namn" source="name" validate={[required()]} />
      <TextInput source="phone" label="Telefonnummer" />
      <TextInput source="email" label="E-mail" />
      <BooleanInput label="Borttagen" source="deletedAt" />
    </SimpleForm>
  </Edit>
)
