import styled from 'styled-components'
import { Link as ReactLink } from 'react-router-dom'

export const Link = styled(ReactLink)`
  font-family: Calibre;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.web.darker};
  text-decoration: none;
  cursor: pointer;

  transition: filter 0.1s ease-in-out;

  &:hover {
    filter: brightness(150%);
  }
`
