import { IAdmin } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { useEffect } from 'react'

export const useIdentity = () => {
  const identity = useApiState<IAdmin>(`v1/admin/accounts/me`)

  useEffect(() => {
    getIdentityHandler()
  }, [])

  const getIdentityHandler = async () => {
    await identity.get()
  }

  return {
    identity,
  }
}
