import React, { useCallback, useState, useEffect } from 'react'
import * as S from './LoginForm.style'
import { useLogin } from 'react-admin'
import { useAuth } from '@bonliva-auth/context'
import { UserType } from '@bonliva-auth/api'
import BankIdModal from './BankIdModal/BankIdModal'
import { Icon } from '@bonliva-traits/web-icons'
import { Status } from '@bonliva-auth/context/context'
import { useNavigate } from 'react-router-dom'

export const LoginForm = () => {
  const [showBankIdModal, setShowBankIdModal] = useState(false)
  const [openOnThisDevice, setOpenOnThisDevice] = useState(false)
  const auth = useAuth()
  const navigate = useNavigate()
  const userLogin = useLogin()

  const initLoginHandler = useCallback(
    async (thisDevice: boolean) => {
      await auth.login(UserType.Admin)
      setShowBankIdModal(true)
      setOpenOnThisDevice(thisDevice)
    },
    [auth]
  )

  useEffect(() => {
    if (auth.status === Status.Authenticated) {
      userLogin({})

      const redirectPathname = window.localStorage.getItem(
        'ra.redirectPathname'
      )
      if (redirectPathname) {
        window.localStorage.removeItem('ra.redirectPathname')
        navigate(redirectPathname)
      }
    }
  }, [auth.status])

  return (
    <S.Wrapper style={{ width: '600px' }}>
      <S.Title>
        <Icon src="Bonliva" />
      </S.Title>
      <S.PrimaryButton onClick={() => initLoginHandler(false)}>
        Använd Mobilt BankID
      </S.PrimaryButton>
      <S.SecondaryButton onClick={() => initLoginHandler(true)}>
        BankID på den här enheten
      </S.SecondaryButton>
      {showBankIdModal && (
        <BankIdModal
          isOpen={showBankIdModal}
          setIsOpen={setShowBankIdModal}
          openOnThisDevice={openOnThisDevice}
          setOpenOnThisDevice={setOpenOnThisDevice}
        />
      )}
    </S.Wrapper>
  )
}
