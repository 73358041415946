import { createTheme, ThemeOptions } from '@mui/material/styles'
import { createTheme as createThemeV4 } from '@material-ui/core/styles'
import { defaultTheme } from 'react-admin'
import { defaultTheme as bonlivaTheme } from '@bonliva-ui/core/theme'
import CalibreRegular from '@bonliva-ui/assets/fonts/Calibre-Regular.otf'
import CalibreBlack from '@bonliva-ui/assets/fonts/Calibre-Black.otf'
import CalibreBlackItalic from '@bonliva-ui/assets/fonts/Calibre-BlackItalic.otf'
import CalibreBold from '@bonliva-ui/assets/fonts/Calibre-Bold.otf'
import CalibreBoldItalic from '@bonliva-ui/assets/fonts/Calibre-BoldItalic.otf'
import CalibreLight from '@bonliva-ui/assets/fonts/Calibre-Light.otf'
import CalibreLightItalic from '@bonliva-ui/assets/fonts/Calibre-LightItalic.otf'
import CalibreMedium from '@bonliva-ui/assets/fonts/Calibre-Medium.otf'
import CalibreMediumItalic from '@bonliva-ui/assets/fonts/Calibre-MediumItalic.otf'
import CalibreRegularItalic from '@bonliva-ui/assets/fonts/Calibre-RegularItalic.otf'
import CalibreSemiBold from '@bonliva-ui/assets/fonts/Calibre-Semibold.otf'
import CalibreSemiBoldItalic from '@bonliva-ui/assets/fonts/Calibre-SemiboldItalic.otf'
import CalibreThin from '@bonliva-ui/assets/fonts/Calibre-Thin.otf'
import CalibreThinItalic from '@bonliva-ui/assets/fonts/Calibre-ThinItalic.otf'

const theme = {
  sidebar: {
    width: 290, // The default value is 240
  },
  palette: {
    primary: {
      main: bonlivaTheme.colors.admin.primary,
      light: bonlivaTheme.colors.admin.primaryLight,
      dark: bonlivaTheme.colors.admin.primaryDark,
    },
    secondary: {
      main: bonlivaTheme.colors.admin.secondary,
      light: bonlivaTheme.colors.admin.secondaryLight,
      dark: bonlivaTheme.colors.admin.secondaryDark,
    },
    error: {
      main: bonlivaTheme.colors.admin.error,
      light: bonlivaTheme.colors.admin.lightError,
    },
    info: {
      main: bonlivaTheme.colors.admin.info,
    },
    success: {
      main: bonlivaTheme.colors.admin.success,
    },
  },
  typography: {
    fontFamily: 'Calibre, sans-serif',
    fontSize: 16,
  },
  components: {
    ...defaultTheme.components,
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {},
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '400px',
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          backgroundColor: bonlivaTheme.colors.admin.background,
          '& .RaLayout-content': {
            backgroundColor: bonlivaTheme.colors.admin.background,
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          '& .RaList-content': {
            boxShadow: 'none',
          },
        },
      },
    },
    RaTabbedShowLayout: {
      styleOverrides: {
        root: {
          '& .RaTabbedShowLayout-content': {
            fontSize: '18px',
            margin: '18px',
          },
        },
      },
    },
    RaSimpleShowLayout: {
      styleOverrides: {
        root: {
          fontSize: '18px',
          margin: '18px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          color: '#09121F',
          backgroundColor: '#fff',
        },
      },
    },
    RaMenu: {
      styleOverrides: {
        root: {
          height: '100vh',
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          height: '100vh',
          '& .RaSidebar-fixed': {
            height: '100vh',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            height: '56px',
            fontSize: '18px',
            backgroundColor: bonlivaTheme.colors.admin.lightBlue,
            fontWeight: '600',
            color: bonlivaTheme.colors.admin.darkGray,
            cursor: 'auto',
            '& .MuiTableSortLabel-icon': {
              display: 'inline',
            },
            '& .MuiTableSortLabel:active': {
              opacity: '1',
            },
          },
          '& .RaDatagrid-row': {
            cursor: 'pointer',
            height: '56px',
          },
        },
      },
    },
  },
}

export const latestTheme = createTheme({
  ...theme,
  typography: {
    fontFamily: 'Calibre, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 400; 
            src: url(${CalibreRegular}) format('opentype')
          }
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 900; 
            src: url(${CalibreBlack}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 400; 
            src: url(${CalibreBlackItalic}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 700; 
            src: url(${CalibreBold}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 700; 
            src: url(${CalibreBoldItalic}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 300; 
            src: url(${CalibreLight}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 300; 
            src: url(${CalibreLightItalic}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 500; 
            src: url(${CalibreMedium}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 500; 
            src: url(${CalibreMediumItalic}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 400; 
            src: url(${CalibreRegularItalic}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 600; 
            src: url(${CalibreSemiBold}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 600; 
            src: url(${CalibreSemiBoldItalic}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: normal; 
            font-weight: 100; 
            src: url(${CalibreThin}) format('opentype')
        }
        @font-face {
            font-family: Calibre;
            font-style: italic; 
            font-weight: 100; 
            src: url(${CalibreThinItalic}) format('opentype')
        }
      `,
    },
  },
})

export const legacyTheme = createThemeV4({
  ...theme,
  props: {},
}) as ThemeOptions
