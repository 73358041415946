import { useEffect, useRef } from 'react'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'
import { useDebouncedCallback, Options } from 'use-debounce'

function useInterval(
  callback: () => Promise<void> | void,
  delay: number | null,
  args?: ReadonlyArray<unknown>,
  options: Options = { leading: true, trailing: true }
) {
  const savedCallback = useRef(callback)

  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback
  }, [callback])

  const debouncedCallback = useDebouncedCallback(
    async () => await savedCallback.current(),
    undefined,
    options
  )

  useEffect(() => {
    callback()
  }, args || [])

  useEffect(() => {
    if (!delay && delay !== 0) return
    const id = setInterval(debouncedCallback, delay)
    return () => clearInterval(id)
  }, [delay])
}
export default useInterval
