import * as React from 'react'
import { HtmlHTMLAttributes, ReactNode, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Card, Avatar, SxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import LockIcon from '@mui/icons-material/Lock'
import { useNavigate } from 'react-router-dom'
import { useCheckAuth } from 'ra-core'
import { LoginForm } from '../forms/LoginForm'

export const LoginPage = (props: LoginProps) => {
  const { backgroundImage, ...rest } = props
  const containerRef = useRef<HTMLDivElement>(null)
  let backgroundImageLoaded = false
  const checkAuth = useCheckAuth()
  const navigate = useNavigate()
  useEffect(() => {
    checkAuth({}, false)
      .then(() => {
        // already authenticated, redirect to the home page
        navigate(window.location.pathname)
      })
      .catch(() => {
        // not authenticated, stay on the login page
        window.localStorage.setItem(
          'ra.redirectPathname',
          window.location.pathname
        )
      })
  }, [checkAuth, navigate])

  const updateBackgroundImage = () => {
    if (!backgroundImageLoaded && containerRef.current) {
      containerRef.current.style.backgroundImage = `url(${backgroundImage})`
      backgroundImageLoaded = true
    }
  }

  // Load background image asynchronously to speed up time to interactive
  const lazyLoadBackgroundImage = () => {
    if (backgroundImage) {
      const img = new Image()
      img.onload = updateBackgroundImage
      img.src = backgroundImage
    }
  }

  useEffect(() => {
    if (!backgroundImageLoaded) {
      lazyLoadBackgroundImage()
    }
  })
  return (
    <Root {...rest} ref={containerRef}>
      <Card className={LoginClasses.card}>
        <div className={LoginClasses.avatar}>
          <Avatar className={LoginClasses.icon}>
            <LockIcon />
          </Avatar>
        </div>
        <LoginForm />
      </Card>
    </Root>
  )
}

export interface LoginProps extends HtmlHTMLAttributes<HTMLDivElement> {
  backgroundImage?: string
  children?: ReactNode
  className?: string
  sx?: SxProps
}

const PREFIX = 'RaLogin'
export const LoginClasses = {
  card: `${PREFIX}-card`,
  avatar: `${PREFIX}-avatar`,
  icon: `${PREFIX}-icon`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  height: '1px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage:
    'radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)',

  [`& .${LoginClasses.card}`]: {
    minWidth: 300,
    marginTop: '6em',
  },
  [`& .${LoginClasses.avatar}`]: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center',
  },
  [`& .${LoginClasses.icon}`]: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    backgroundColor: theme.palette.secondary[500],
  },
}))

LoginPage.propTypes = {
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
}
