export enum UserType {
  Patient = 'patient',
  Treater = 'treater',
}

export enum BookingType {
  Initial = 'initial',
  FollowUp = 'follow-up',
}

export enum BookingMeetingType {
  Digital = 'digital',
  Physical = 'physical',
}

export enum BookingStatus {
  Reserved = 'reserved',
  Created = 'created',
  Paid = 'paid',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Aborted = 'aborted',
  Failed = 'failed',
  NoShow = 'no-show',
}

export enum PaymentStatus {
  Pending = 'pending',
  Paid = 'paid',
  Failed = 'failed',
  Refunded = 'refunded',
}

export enum PaymentMethod {
  Stripe = 'stripe',
  StripeCheckout = 'stripe_checkout',
  Swish = 'swish',
}

export enum PaymentProductStatus {
  PendingPayment = 'pending_payment',
  FailedPayment = 'failed_payment',
  CompletedPayment = 'completed_payment',
  PendingRefund = 'pending_refund',
  CompletedRefund = 'completed_refund',
}

export enum PatientNotificationType {
  NewReferralPlan = 'new_referral_plan',
  TreatmentForm = 'treatment_form',
  BookingConfirmation = 'booking_confirmation',
  BookingCancellation = 'booking_cancellation',
  Receipt = 'receipt',
  RefundReceipt = 'refund_receipt',
  NewTreatmentPlan = 'new_treatment_plan',
  UpdatedTreatmentPlan = 'updated_treatment_plan',
  UpdatedReferralPlan = 'updated_referral_plan',
  NewPayment = 'new_payment',
  PaymentReminder = 'payment_reminder',
  Message = 'message',
  MissedAppointment = 'missed_appointment',
  ExpiringReferralPlan = 'expiring_referral_plan',
  ExpiringFirstAppointmentReferralPlan = 'expiring_first_appointment_referral_plan',
}

export enum TreaterNotificationType {
  MovedReferralPlan = 'moved_referral_plan',
  CanceledBooking = 'canceled_booking',
  NewBooking = 'new_booking',
  UpdatedBooking = 'updated_booking',
  AcceptedTreatmentPlan = 'accepted_treatment_plan',
  RejectedTreatmentPlan = 'rejected_treatment_plan',
  CompletedBookingTreatmentForm = 'completed_booking_treatment_form',
  CompletedTreatmentPlanTreatmentForm = 'completed_treatment_plan_treatment_form',
}

export type NotificationType = PatientNotificationType | TreaterNotificationType

export enum HealthCurveType {
  VeryHappy = 'very_happy',
  Happy = 'happy',
  Neutral = 'neutral',
  Down = 'down',
  Sad = 'sad',
}

export enum GraphType {
  Week,
  Month,
  Year,
}

export enum ExercisePartStatus {
  Assigned = 'assigned',
  Ongoing = 'ongoing',
  Completed = 'completed',
}

export enum AssignmentStatus {
  Assigned = 'assigned',
  Ongoing = 'ongoing',
  Completed = 'completed',
}

export enum AssignmentType {
  Exercise = 'exercise',
  Program = 'program',
}

export enum DeviationType {
  Unavailable = 'unavailable',
  Available = 'available',
}

export enum KpiType {
  Treater = 'treater',
  CareProvider = 'care_provider',
}

export enum WeekdaysType {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
}

export enum EmailPreferenceType {
  CompletedBookingTreatmentForm = 'completed_booking_treatment_form',
  NewBooking = 'new_booking',
  UpdatedBooking = 'updated_booking',
  CanceledBooking = 'canceled_booking',
  BookingReminderDayBefore = 'booking_reminder_day_before',
  AcceptedTreatmentPlan = 'accepted_treatment_plan',
  RejectedTreatmentPlan = 'rejected_treatment_plan',
  CompletedTreatmentPlanTreatmentForm = 'completed_treatment_plan_treatment_form',
}

export enum TreatmentPlanStatus {
  Draft = 'draft',
  Pending = 'pending',
  Rejected = 'rejected',
  Accepted = 'accepted',
  Completed = 'completed',
}

export enum ComponentType {
  Title = 'text.title',
  Subtitle = 'text.subtitle',
  Body = 'text.body',
  Annotation = 'intractable.annotation',
  Image = 'media.image',
  Audio = 'media.audio',
  Video = 'media.video',
  Youtube = 'media.youtube',
}

export enum ReminderType {
  NoMedicalRecord = 'no_medical_record',
  LateCanceledBooking = 'late_canceled_booking',
}
