import React, { useCallback } from 'react'
import * as S from './BankIdModal.style'
import { useAuth } from '@bonliva-auth/context'
import useInterval from '@bonliva-traits/hooks/useInterval'
import { AxiosError } from '@bonliva-traits/api'
import { Icon } from '@bonliva-traits/web-icons'
import QRCode from 'react-qr-code'
import { messages } from '@bonliva-auth/api/messages'
import Spinner from '@bonliva-admin/spinner'

type BankIdProps = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  openOnThisDevice: boolean
  setOpenOnThisDevice: (openOnThisDevice: boolean) => void
}

const BankIdModal: React.FC<BankIdProps> = (props) => {
  const auth = useAuth()
  const [qrCode, setQrCode] = React.useState('')
  const [hasError, setHasError] = React.useState(false)

  const openBankIdHandler = useCallback(() => {
    window.open(auth.getBankIDLink(window.location.hash), '_self')
  }, [auth])

  useInterval(
    async () => {
      if (!props.isOpen) return
      try {
        await auth.verify()
      } catch (error) {
        const err = error as AxiosError
        if (err.response?.status === 404) setHasError(true)
      }
    },
    props.isOpen && !hasError ? 2000 : null
  )

  useInterval(
    async () => {
      props.isOpen && setQrCode((await auth.qrCode()).data.qrData)
    },
    !props.openOnThisDevice && !hasError && props.isOpen ? 1000 : null
  )

  const onDismissHandler = useCallback(() => {
    props.setIsOpen(false)
    props.setOpenOnThisDevice(false)
    setHasError(false)
  }, [])

  return (
    <S.Modal>
      <S.ModalContent>
        <S.ContentWrapper>
          <S.CloseButton onClick={onDismissHandler}>
            <Icon src="Close" />
          </S.CloseButton>
          <S.ModalTitle>
            <Icon src="Bonliva" width="200px" />
          </S.ModalTitle>
          {props.openOnThisDevice ? (
            <S.Content>
              <div>BankID på den här enheten</div>
              <div>{messages[auth.messageCode || '']}</div>

              <S.OpenBankIDButton onClick={openBankIdHandler}>
                Öppna BankID
              </S.OpenBankIDButton>

              <div>Har du BankID på en annan enhet?</div>

              <S.ThisDeviceButton
                onClick={() => props.setOpenOnThisDevice(false)}
              >
                Klicka här för att komma till en QR-kod för inloggning
              </S.ThisDeviceButton>
            </S.Content>
          ) : (
            <S.Content>
              <div>Logga in med mobilt BankID</div>
              <div>{messages[auth.messageCode || '']}</div>

              <div>
                {qrCode ? <QRCode value={qrCode} size={208} /> : <Spinner />}
              </div>

              <div>Har du BankID på den här enheten?</div>

              <S.ThisDeviceButton
                onClick={() => props.setOpenOnThisDevice(true)}
              >
                Logga in med BankID på den här enheten
              </S.ThisDeviceButton>
            </S.Content>
          )}
        </S.ContentWrapper>
      </S.ModalContent>
    </S.Modal>
  )
}

export default BankIdModal
