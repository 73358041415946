import {
  ActionMap,
  ResponseError,
  RootCreatorPayload,
} from '@bonliva-core/store'
import { IAppointment } from '../types'

export type State = {
  isLoading: boolean
  hasLoaded: boolean
  data: IAppointment[]
  hasError?: ResponseError<IAppointment>
}

export enum Types {
  CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT',
}

export type Payload = RootCreatorPayload<IAppointment[]> & {
  [Types.CANCEL_APPOINTMENT]: string
}

export type Action = ActionMap<Payload>
