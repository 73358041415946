import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  Edit,
  TopToolbar,
  ListButton,
  AutocompleteInput,
  ReferenceInput,
  ReferenceField,
} from 'react-admin'
import CustomEditButton from '@bonliva-admin/custom-editbutton'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import CustomDeletebutton from '@bonliva-admin/custom-deletebutton'

const filters = [
  <ReferenceInput
    key="specialist-competence-filter"
    label="Specialist Competence"
    source="specialistCompetenceId"
    reference="specialist-competences"
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
  <ReferenceInput
    key="category-filter"
    label="Category"
    source="treatmentCategoryId"
    reference="treatment-categories"
  >
    <AutocompleteInput
      optionText="category"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
]

export const SpecialistCompetenceTreatmentCategoryList = () => (
  <List filters={filters} perPage={25}>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <ReferenceField
        label="Specialist Competence"
        source="specialistCompetenceId"
        reference="specialist-competences"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="Category"
        source="treatmentCategoryId"
        reference="treatment-categories"
      >
        <TextField source="category" sortable={true} />
      </ReferenceField>
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomDeletebutton />
        <CustomEditButton callToAction={true} />
      </div>
    </Datagrid>
  </List>
)

export const SpecialistCompetenceTreatmentCategoryCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <ReferenceInput
        source="specialistCompetenceId"
        reference="specialist-competences"
      >
        <AutocompleteInput
          label="Specialist Competence"
          optionText="name"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
      <ReferenceInput
        source="treatmentCategoryId"
        reference="treatment-categories"
      >
        <AutocompleteInput
          label="Category"
          optionText="category"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const SpecialistCompetenceTreatmentCategoryEdit = () => (
  <Edit
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <ReferenceInput
        source="specialistCompetenceId"
        reference="specialist-competences"
      >
        <AutocompleteInput
          label="Specialist Competence"
          optionText="name"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
      <ReferenceInput
        source="treatmentCategoryId"
        reference="treatment-categories"
      >
        <AutocompleteInput
          label="Category"
          optionText="category"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
