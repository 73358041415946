import React from 'react'
import { defaultTheme } from '@bonliva-ui/core/theme'
import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import {
  List,
  TextField,
  Datagrid,
  Show,
  TabbedShowLayout,
  Tab,
  SimpleForm,
  Create,
  TextInput,
  required,
  ListButton,
  TopToolbar,
  SearchInput,
  Edit,
  BooleanInput,
  BooleanField,
  AutocompleteInput,
  ReferenceInput,
  ImageField,
  ReferenceField,
} from 'react-admin'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import CustomEditbutton from '@bonliva-admin/custom-editbutton'

const adminFilters = [
  <SearchInput
    key="care-provider-filter-care-provider"
    source="name"
    alwaysOn
    parse={(searchText) => `like:${searchText}`}
    format={(searchText) => searchText?.replace('like:', '')}
  />,
]

export const CareProviderList = () => (
  <List
    filters={adminFilters}
    sort={{ field: 'CareProvider.name', order: 'ASC' }}
  >
    <Datagrid bulkActionButtons={false}>
      <ReferenceField
        label="Logotyp"
        source="logoId"
        reference="files"
        sortable={false}
      >
        <ImageField
          source="url"
          title="File not supported"
          sx={{
            '& .RaImageField-image': {
              height: '40px',
              objectFit: 'cover',
            },
          }}
        />
      </ReferenceField>
      <TextField label="Namn" source="name" sortable={false} />
      <TextField
        label="Organisationsnummer"
        source="corporateNumber"
        sortable={false}
      />
      <TextField label="Phone" source="phone" sortable={false} />
      <TextField label="E-mail" source="email" sortable={false} />
      <BooleanField label="Extern" source="isExternal" sortable={false} />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomEditbutton />
        <CustomShowbutton />
      </div>
    </Datagrid>
  </List>
)

export const CareProviderShow = () => (
  <Show
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <TabbedShowLayout>
      <Tab
        label="Vårdgivare information"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceField
          label="Logotyp"
          source="logoId"
          reference="files"
          sortable={false}
        >
          <ImageField
            source="url"
            title="File not supported"
            sx={{
              '& .RaImageField-image': {
                height: '40px',
                objectFit: 'cover',
              },
            }}
          />
        </ReferenceField>
        <TextField source="id" label="Id" />
        <TextField source="name" label="Namn" />
        <TextField source="corporateNumber" label="Organisationsnummer" />
        <TextField source="email" label="E-mail" />
        <TextField source="phone" label="Telefonnummer" />
        <TextField source="receiptPrefix" label="Kvitto prefix" />
        <BooleanField source="isExternal" label="Extern" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const CareProviderCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput
        label="Organisationsnummer"
        source="corporateNumber"
        validate={[required()]}
      />
      <TextInput label="Namn" source="name" validate={[required()]} />
      <TextInput label="E-mail" source="email" validate={[required()]} />
      <TextInput label="Telefonnummer" source="phone" validate={[required()]} />
      <TextInput
        label="Kvitto prefix"
        source="receiptPrefix"
        validate={[required()]}
      />
      <BooleanInput label="Extern" source="isExternal" />
      <ReferenceInput
        label="Logotyp"
        source="logoId"
        reference="files"
        validate={[required()]}
      >
        <AutocompleteInput
          label="Logotyp"
          optionText={(o) => (
            <img
              height="40px"
              width="40px"
              src={o.url}
              alt="File not supported"
            />
          )}
          inputText={(o) => o.name}
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const CareProviderEdit = () => (
  <Edit
    transform={(data) => {
      delete data.stripeAccountId

      return {
        ...data,
      }
    }}
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput
        label="Organisationsnummer"
        source="corporateNumber"
        validate={[required()]}
        disabled={true}
      />
      <TextInput label="Namn" source="name" validate={[required()]} />
      <TextInput label="E-mail" source="email" validate={[required()]} />
      <TextInput label="Telefonnummer" source="phone" validate={[required()]} />
      <TextInput
        label="Kvitto prefix"
        source="receiptPrefix"
        validate={[required()]}
      />
      <BooleanInput label="Extern" source="isExternal" />
      <ReferenceInput
        label="Logotyp"
        source="logoId"
        reference="files"
        validate={[required()]}
      >
        <AutocompleteInput
          label="Logotyp"
          optionText={(o) => (
            <img
              height="40px"
              width="40px"
              src={o.url}
              alt="File not supported"
            />
          )}
          inputText={(o) => o.name}
        />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)
