import styled from 'styled-components'

interface IProps {
  width?: string
  border?: string
  paddingLeft?: string
}

export const StyledInputOuterContainer = styled('div')`
  display: flex;
  background-color: ${(props) => props.theme.colors.web.white};
  align-items: center;
  flex-direction: row;
  position: relative;
  border-radius: ${(props) => props.theme.radius.rounded};
`
export const StyledInputInnerContainer = styled('div')`
  display: flex;
  position: absolute;
  top: 12px;
  left: 15px;
  justify-content: center;
  align-items: center;
`

export const StyledInput = styled.input<IProps>`
  border: ${(props) => (props.border ? props.border : 'none')};
  width: ${(props) => (props.width ? props.width : '449px')};
  height: 40px;
  border-radius: 8px;
  padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '40px')};
  color: ${(props) => props.theme.colors.web.darker};
`

export const StyledInputIconContainer = styled.div`
  position: relative;
  svg {
    position: absolute;
    top: 12px;
    left: 16px;
    color: #ff867b;
  }
`

export const Input = styled.input`
  min-height: 42px;
  border: 1px solid ${({ theme }) => theme.colors.web.neutral};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  font-family: 'Calibre';
  font-weight: 300;
  font-size: 16px;
  min-height: 46px;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.web.primary};
  }
`
