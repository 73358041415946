import React from 'react'
import { ShowButton } from 'react-admin'
import { defaultTheme } from '@bonliva-ui/core/theme'

const CustomShowButton = () => {
  return (
    <ShowButton
      sx={{
        backgroundColor: defaultTheme.colors.admin.primary,
        border: '1px solid transparent',
        color: 'white',
        fontWeight: 'bold',
        padding: defaultTheme.margin['3xs'],
        margin: '0 6px',
        ':hover': {
          backgroundColor: defaultTheme.colors.admin.primary,
        },
        ':only-child': {
          margin: '0 0',
        },
      }}
      label="Visa"
    />
  )
}

export default CustomShowButton
