import styled from 'styled-components'
import { ButtonSecondary } from '@bonliva-ui/web'

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const DashboardHeader = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 53px;
`

export const SelectWrapper = styled.div`
  width: 320px;
  margin-right: ${({ theme }) => theme.margin.xxs};
`

export const OptionsWrapper = styled.div`
  display: flex;
`

export const OptionButton = styled(ButtonSecondary)<{ selected: boolean }>`
  font-weight: 600;
  width: 70px;
  margin-right: 4px;

  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.admin.primary : theme.colors.admin.white};

  color: ${({ theme, selected }) =>
    selected ? theme.colors.admin.white : theme.colors.admin.primary};
`

export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const CalendarWrapper = styled.div`
  height: 100%;
  width: 320px;
  margin-right: ${({ theme }) => theme.margin.xxs};

  .date-picker {
    color: ${({ theme }) => theme.colors.web.darkest};
    border: 1px solid ${({ theme }) => theme.colors.web.neutral};
    border-radius: ${({ theme }) => theme.radius.rounded};
    padding: ${({ theme }) => theme.margin.xxs};
    font-family: 'Calibre';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 46px;

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.web.primary};
    }
  }

  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.web.neutral};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.web.white};
    border-color: ${({ theme }) => theme.colors.web.neutral};
  }

  .react-datepicker__day {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.web.secondaryDark};
    border-radius: 50%;

    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    :hover {
      color: ${({ theme }) => theme.colors.web.white};
      background-color: ${({ theme }) => theme.colors.web.primary}cc;
      border-radius: 50%;
    }
  }

  .react-datepicker__day-name {
    &:first-child {
      color: transparent;
    }
  }

  .react-datepicker__week-number {
    background-color: ${({ theme }) => theme.colors.web.lightest};
    border-radius: 2px;
  }

  .react-datepicker__day--in-range {
    background-color: ${({ theme }) => theme.colors.web.primary};
    color: white;
  }

  .react-datepicker__day--in-selecting-range {
    background-color: ${({ theme }) => theme.colors.web.primary}cc;
    color: white;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.web.primary};
    color: ${({ theme }) => theme.colors.web.white};
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker__day--outside-month {
    opacity: 0.2;
    pointer-events: none;
    background-color: transparent;
  }

  .react-datepicker__triangle {
    display: none;
  }
`

export const GraphsWrapper = styled.div`
  height: 100%;
`
