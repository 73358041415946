import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  SearchInput,
  TopToolbar,
  ListButton,
  AutocompleteInput,
  ReferenceInput,
  BooleanInput,
} from 'react-admin'
import CustomEditButton from '@bonliva-admin/custom-editbutton'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import CustomDeletebutton from '@bonliva-admin/custom-deletebutton'

const treatmentCategoryFilters = [
  <SearchInput
    key="treatment-filter-treatment"
    source="category"
    alwaysOn
    parse={(searchText) => `like:${searchText}`}
    format={(searchText) => searchText?.replace('like:', '')}
  />,
]

export const TreatmentCategoryList = () => (
  <List
    filters={treatmentCategoryFilters}
    perPage={25}
    sort={{ field: 'category', order: 'ASC' }}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <TextField label="Kategori" source="category" sortable={false} />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomDeletebutton />
        <CustomEditButton callToAction={true} />
      </div>
    </Datagrid>
  </List>
)

export const TreatmentCategoryCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput label="Namn" source="category" />
      <ReferenceInput
        source="treatmentFormTemplateId"
        reference="treatment-form-templates"
      >
        <AutocompleteInput
          label="Treatment Form Template"
          optionText="title"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
      <BooleanInput label="Is Company Care" source="isCompanyCare" />
    </SimpleForm>
  </Create>
)

export const TreatmentCategoryEdit = () => (
  <Edit
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSavebutton />}>
      <TextInput label="Namn" source="category" />
      <ReferenceInput
        source="treatmentFormTemplateId"
        reference="treatment-form-templates"
      >
        <AutocompleteInput
          label="Treatment Form Template"
          optionText="title"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>
      <BooleanInput label="Is Company Care" source="isCompanyCare" />
    </SimpleForm>
  </Edit>
)
