import { createStore, RootCreatorTypes } from '@bonliva-core/store'
import { State, Action } from './types'

const initialState: State = {
  isLoading: false,
  hasLoaded: false,
  data: {
    id: '',
    personalNumber: '',
    name: '',
    hourlyRate: 0,
    createdAt: '',
  },
}

const store = createStore<State, Action>({
  name: 'treater-profile',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case RootCreatorTypes.UNLOAD:
        return initialState

      case RootCreatorTypes.PENDING:
        return {
          ...state,
          isLoading: true,
          hasError: undefined,
        }

      case RootCreatorTypes.SUCCESS:
        return {
          ...state,
          hasLoaded: true,
          isLoading: false,
          data: action.payload,
        }

      case RootCreatorTypes.FAIL:
        return {
          ...state,
          isLoading: false,
          hasError: action.payload,
        }

      default:
        return state
    }
  },
})

export default store
