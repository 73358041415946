import React, { useEffect, useState } from 'react'
import useApiState from '@bonliva-traits/hooks/useApiState'
import * as S from './Dashboard.style'
import Select from '@bonliva-components/web/shared/Select'
import {
  IBookingFeedbackKpi,
  IBookingFeedbackKpiOption,
} from '@bonliva-traits/api/types'
import { getLabel } from './getLabel'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useApi } from '@bonliva-traits/api'
import { Graph } from '../Graph/Graph'
import { last30Days, presetsOptions } from './Presets'
import {
  ITreater,
  ICareProvider,
  IKpiUserOrProvider,
} from '@bonliva-traits/api/types'
import { KpiType } from '@bonliva-traits/api/enums'

type DashboardProps = {
  path: string
}

export const Dashboard = (props: DashboardProps) => {
  const api = useApi()
  const dashboard = useApiState<IBookingFeedbackKpi[]>(props.path)
  const [data, setData] = useState<
    | {
        label: string
        value: string
      }[]
    | []
  >([])

  const [selectedKpi, setSelectedKpi] = useState<IBookingFeedbackKpi>()

  const [dateRange, setDateRange] = useState(last30Days.value)
  const [hideSelect, setHideSelect] = useState(false)
  const [selectedOption, setSelectedOption] = useState<
    IBookingFeedbackKpiOption | undefined
  >({
    format: 'YYYY-MM-DD',
    groupBy: 'day',
    label: 'Dag',
  })
  const [selectedUserProvider, setSelectedUserProvider] = useState<
    IKpiUserOrProvider | undefined
  >()

  const allTreaters = useApiState<ITreater[]>('v1/admin/treaters')
  const allCareProviders = useApiState<ICareProvider[]>(
    'v1/admin/care-providers'
  )

  useEffect(() => {
    allTreaters.get()
    allCareProviders.get()
  }, [])

  const dashboardDataHandler = async () => {
    const result = await dashboard.get()
    setSelectedKpi(result[0])
    setSelectedOption(result[0].options?.[0])
  }

  const onDateChange = (dates: [Date, Date]) => {
    const [start, end] = dates
    setDateRange({
      startDate: start,
      endDate: end,
    })
  }

  const handleGetKpiData = async () => {
    if (!selectedKpi || !dateRange) return

    const body = {
      slug: selectedKpi.slug,
      fromDate: dateRange.startDate.toISOString(),
      toDate: dateRange.endDate.toISOString(),
      option: selectedKpi.options ? selectedOption : undefined,
      userOrProvider:
        !selectedOption?.typeSelect && selectedUserProvider
          ? {
              id: selectedUserProvider?.id,
              type: selectedUserProvider?.type,
            }
          : undefined,
      disableTypeSelect: selectedKpi.disableTypeSelect,
    }

    const result = await api.post(props.path, body)

    if (!result) throw new Error('something went wrong getting the data')
    setData(result.data)
  }

  useEffect(() => {
    handleGetKpiData()
  }, [selectedKpi, selectedOption, dateRange, selectedUserProvider])

  useEffect(() => {
    dashboardDataHandler()
  }, [])

  return (
    <S.DashboardContainer>
      <S.DashboardHeader>
        <S.SelectWrapper>
          <Select
            options={(dashboard.data || []).map((o) => ({
              slug: o.slug,
              label: o.label,
              suffix: o.suffix,
            }))}
            value={selectedKpi}
            onChange={(o) => {
              const kpi = dashboard.data?.find((kpi) => o?.slug === kpi.slug)
              setSelectedKpi(kpi)
              setSelectedOption(kpi?.options?.[0])
              const shouldHideSelect = kpi?.disableTypeSelect || false
              setHideSelect(shouldHideSelect)
            }}
            placeholder="Välj KPI"
            getOptionValue={(o) => o.slug}
            getOptionLabel={(o) => getLabel(o.slug)}
          />
          {!hideSelect && (
            <div style={{ marginTop: '10px' }}>
              <Select
                options={[
                  ...(allTreaters?.data || []).map((item) => ({
                    value: { ...item, type: KpiType.Treater as const },
                    label: item.name,
                  })),
                  ...(allCareProviders?.data || []).map((item) => ({
                    value: { ...item, type: KpiType.CareProvider as const },
                    label: item.name,
                  })),
                ]}
                value={
                  selectedUserProvider
                    ? {
                        value: {
                          type: selectedUserProvider.type,
                          ...(selectedUserProvider.type === KpiType.Treater
                            ? allTreaters?.data?.find(
                                (t) => t.id === selectedUserProvider.id
                              )
                            : allCareProviders?.data?.find(
                                (c) => c.id === selectedUserProvider.id
                              )),
                        },
                        label:
                          selectedUserProvider.type === KpiType.Treater
                            ? allTreaters?.data?.find(
                                (t) => t.id === selectedUserProvider.id
                              )?.name
                            : allCareProviders?.data?.find(
                                (c) => c.id === selectedUserProvider.id
                              )?.name,
                      }
                    : undefined
                }
                onChange={(selected) => {
                  if (selected) {
                    const value = selected.value as
                      | ({ type: KpiType.Treater } & ITreater)
                      | ({ type: KpiType.CareProvider } & ICareProvider)

                    setSelectedUserProvider({
                      type: value.type,
                      id: value.id,
                    })
                  }
                }}
                placeholder="Select Treater/Care Provider"
              />
            </div>
          )}
          <div style={{ marginTop: '10px' }}>
            <Select
              placeholder="Välj fördefinierad tid"
              onChange={(e) =>
                setDateRange(e?.value as { startDate: Date; endDate: Date })
              }
              defaultValue={last30Days}
              options={presetsOptions}
            />
          </div>
        </S.SelectWrapper>
        {selectedKpi && (
          <S.OptionsWrapper>
            {selectedKpi.options?.map((option) => {
              return (
                <S.OptionButton
                  selected={selectedOption?.label === option.label}
                  key={option.label}
                  onClick={() => setSelectedOption(option)}
                >
                  {option.label}
                </S.OptionButton>
              )
            })}
          </S.OptionsWrapper>
        )}
      </S.DashboardHeader>
      <S.DashboardWrapper>
        <S.CalendarWrapper>
          <DatePicker
            onChange={onDateChange}
            startDate={dateRange?.startDate}
            endDate={dateRange?.endDate}
            selectsRange
            inline
            selected={dateRange?.endDate}
          />
        </S.CalendarWrapper>
        <S.GraphsWrapper>
          <Graph data={data} label={selectedKpi?.slug || ''} />
        </S.GraphsWrapper>
      </S.DashboardWrapper>
    </S.DashboardContainer>
  )
}
