import {
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfYear,
  endOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns'

// Today
const today = {
  label: 'Idag',
  value: {
    startDate: startOfDay(new Date()),
    endDate: endOfDay(new Date()),
  },
}

// Yesterday
const yesterday = {
  label: 'Igår',
  value: {
    startDate: startOfDay(subDays(new Date(), 1)),
    endDate: endOfDay(subDays(new Date(), 1)),
  },
}

// Last week
const lastWeek = {
  label: 'Förra veckan',
  value: {
    startDate: startOfWeek(subDays(new Date(), 7)),
    endDate: endOfWeek(subDays(new Date(), 7)),
  },
}

// Last 7 days
const last7Days = {
  label: 'Senaste 7 dagar',
  value: {
    startDate: startOfDay(subDays(new Date(), 6)),
    endDate: endOfDay(new Date()),
  },
}

// Last 28 days
const last28Days = {
  label: 'Senaste 28 dagar',
  value: {
    startDate: startOfDay(subDays(new Date(), 27)),
    endDate: endOfDay(new Date()),
  },
}

// Last 30 days
export const last30Days = {
  label: 'Senaste 30 dagar',
  value: {
    startDate: startOfDay(subDays(new Date(), 29)),
    endDate: endOfDay(new Date()),
  },
}

// Last 90 days
const last90Days = {
  label: 'Senaste 90 dagar',
  value: {
    startDate: startOfDay(subDays(new Date(), 89)),
    endDate: endOfDay(new Date()),
  },
}

// Last 12 months
const last12Months = {
  label: 'Senaste 12 månader',
  value: {
    startDate: startOfMonth(subMonths(new Date(), 11)),
    endDate: endOfMonth(new Date()),
  },
}

// this year
const currentYear = {
  label: 'Innevarande år',
  value: {
    startDate: startOfYear(new Date()),
    endDate: new Date(),
  },
}

// last year
const lastYear = {
  label: 'Föregående år',
  value: {
    startDate: startOfYear(subYears(new Date(), 1)),
    endDate: endOfYear(subYears(new Date(), 1)),
  },
}

export const presetsOptions = [
  today,
  yesterday,
  lastWeek,
  last7Days,
  last28Days,
  last30Days,
  last90Days,
  last12Months,
  currentYear,
  lastYear,
]
