import React, { useEffect, useState } from 'react'
import { Paper } from '@material-ui/core'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { KpiCard } from './components/KpiCard/KpiCard'
import * as S from './AnalysisSummary.style'
import { Graph } from '../components/Graph/Graph'
import { startOfYear, endOfYear, subYears } from 'date-fns'
import { useApi } from '@bonliva-traits/api'

type DashBoardKpi = {
  count: number
  increase: number
}

const AnalysisSummary = () => {
  const api = useApi()
  const [graphData, setGraphData] = useState<
    | {
        label: string
        value: string
      }[]
    | []
  >([])

  const totalUsers = useApiState<DashBoardKpi>(
    `v1/admin/kpis/overview/total-users`
  )
  const totalBookings = useApiState<DashBoardKpi>(
    `v1/admin/kpis/overview/total-bookings`
  )
  const newUsers = useApiState<DashBoardKpi>(
    `v1/admin/kpis/overview/total-users`
  )
  const newTreatmentPlans = useApiState<DashBoardKpi>(
    `v1/admin/kpis/overview/total-users`
  )

  const getDataHandler = async () => {
    await totalUsers.get()
    await totalBookings.get()
    await newUsers.get()
    await newTreatmentPlans.get()
    await getTreatmentPlanDataHandler()
  }

  const getTreatmentPlanDataHandler = async () => {
    const body = {
      slug: 'new-treatment-plans',
      fromDate: startOfYear(subYears(new Date(), 1)),
      toDate: endOfYear(subYears(new Date(), 1)),
      option: {
        format: 'YYYY-MM',
        groupBy: 'month',
        label: 'Månad',
      },
    }

    const result = await api.post(`v1/admin/kpis`, body)

    if (!result) throw new Error('something went wrong getting the data')
    setGraphData(result.data)
  }

  useEffect(() => {
    getDataHandler()
  }, [])

  return (
    <>
      <div style={{ height: '20px' }} />
      <Paper style={{ padding: 16 }} elevation={2}>
        <S.AnalysisSummaryContainer>
          <S.CardContainer>
            {totalUsers.data && (
              <KpiCard label="Totalt antal användare" data={totalUsers.data} />
            )}
            {totalBookings.data && (
              <KpiCard
                label="Totalt antal bokningar"
                data={totalBookings.data}
              />
            )}
            {newUsers.data && (
              <KpiCard label="Nya användare" data={newUsers.data} />
            )}
            {newTreatmentPlans.data && (
              <KpiCard
                label="Nya behandlingsplaner"
                data={newTreatmentPlans.data}
              />
            )}
          </S.CardContainer>
          <S.GraphContainer>
            <Graph data={graphData} label="new-treatment-plans" />
          </S.GraphContainer>
        </S.AnalysisSummaryContainer>
      </Paper>
    </>
  )
}

export default AnalysisSummary
