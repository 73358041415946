import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  Create,
  SimpleForm,
  DateInput,
  Show,
  ListButton,
  TopToolbar,
  TextInput,
  NumberInput,
  FunctionField,
  Edit,
  required,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  ReferenceInput,
  AutocompleteInput,
  minLength,
  regex,
} from 'react-admin'
import CustomShowButton from '@bonliva-admin/custom-showbutton'
import CustomSaveButton from '@bonliva-admin/custom-savebutton'
import CustomEditButton from '@bonliva-admin/custom-editbutton'
import CustomDeleteButton from '@bonliva-admin/custom-deletebutton'
import { defaultTheme } from '@bonliva-ui/core/theme'
import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import { validateSSN } from '../helpers/validateSSN'

const referralFilters = [
  <ReferenceInput
    key="referral-filter-patient"
    source="patientId"
    reference="patients"
  >
    <AutocompleteInput
      optionText="name"
      filterToQuery={(searchText) => ({ _search: `${searchText}` })}
    />
  </ReferenceInput>,
]

export const ReferralPlanList = () => (
  <List
    filters={referralFilters}
    sort={{ field: 'ReferralPlan.firstAppointmentExpiryDate', order: 'ASC' }}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <ReferenceField
        label="Patient"
        reference="patients"
        source="patientId"
        sortable={false}
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField label="Möten totalt" source="numberOfAppointments" />
      <TextField label="Möten kvar" source="numberOfAppointmentsLeft" />
      <DateField
        label="Första samtal senast"
        source="firstAppointmentExpiryDate"
        sortable
        sortBy="ReferralPlan.firstAppointmentExpiryDate"
      />

      <DateField
        label="Giltig till"
        source="expiryDate"
        sortable
        sortBy="ReferralPlan.expiryDate"
      />
      <TextField label="Remittent" source="referralOrigin" />
      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomEditButton />
        <CustomShowButton />
        <CustomDeleteButton />
      </div>
    </Datagrid>
  </List>
)

export const ReferralPlanCreate = () => (
  <Create
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSaveButton />}>
      <TextInput
        label="Patientens personnummer (12 siffror)"
        source="patientPersonalNumber"
        validate={[
          required('Du måste fylla i personnummer'),
          minLength(12, 'Personnumret måste vara 12 siffror'),
          regex(/^[0-9]*$/, 'Personnumret får bara innehålla siffror'),
          validateSSN,
        ]}
      />

      <TextInput label="Patientens namn" source="patientName" />

      <TextInput label="Patientens telefonnummer" source="patientPhoneNumber" />

      <NumberInput
        label="Antal berättigade möten"
        source="numberOfAppointments"
        validate={required()}
      />

      <NumberInput
        label="Patientavgift (i kronor)"
        source="hourlyRate"
        format={(v) => String(v / 100)}
        parse={(v) => Number(v * 100)}
        validate={required()}
      />

      <DateInput
        label="Första samtal måste genomföras senast"
        source="firstAppointmentExpiryDate"
        validate={required()}
      />

      <DateInput
        label="Remiss slutar vara giltigt från och med"
        source="expiryDate"
      />

      <ReferenceInput
        source="treatmentCategoryId"
        reference="treatment-categories"
      >
        <AutocompleteInput
          label="Behandlingsområde"
          optionText="category"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>

      <TextInput
        label="Remittent"
        source="referralOrigin"
        validate={required()}
      />
    </SimpleForm>
  </Create>
)

export const ReferralPlanShow = () => (
  <Show
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <TabbedShowLayout>
      <Tab
        label="Remissinformation"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField source="id" />
        <ReferenceField
          label="Patient"
          reference="patients"
          source="patientId"
          sortable={false}
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>

        <FunctionField
          source="hourlyRate"
          render={(val: { hourlyRate: number }) => `${val.hourlyRate / 100} kr`}
        />

        <TextField
          label="Berättigade möten totalt"
          source="numberOfAppointments"
        />

        <DateField
          label="Första samtal måste genomföras senast"
          source="firstAppointmentExpiryDate"
        />

        <DateField
          label="Remiss slutar vara giltigt från och med"
          source="expiryDate"
        />

        <ReferenceField
          label="Behandlingsområde"
          reference="treatment-categories"
          source="treatmentCategoryId"
          sortable={false}
        >
          <TextField source="category" />
        </ReferenceField>

        <TextField label="Remittent" source="referralOrigin" />
      </Tab>
      <Tab
        label="Kommande bokningar"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="bookings"
          target="referralPlanId"
          label=""
          filter={{ startDate: `gte:${new Date().toISOString()}` }}
          sort={{ field: 'Booking.startDate', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <DateField source="startDate" label="Datum" sortable={false} />
            <ReferenceField
              source="treaterId"
              reference="treaters"
              label="Psykolog"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              source="treatmentCategoryId"
              reference="treatment-categories"
              label="Behandlingsområde"
              sortable={false}
            >
              <TextField source="category" />
            </ReferenceField>
            <TextField source="status" label="Status" sortable={false} />
            <CustomShowbutton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab
        label="Historik"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="bookings"
          target="referralPlanId"
          label=""
          filter={{ startDate: `lt:${new Date().toISOString()}` }}
          sort={{ field: 'Booking.startDate', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <DateField source="startDate" label="Datum" sortable={false} />
            <ReferenceField
              source="treaterId"
              reference="treaters"
              label="Psykolog"
              sortable={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              source="treatmentCategoryId"
              reference="treatment-categories"
              label="Behandlingsområde"
              sortable={false}
            >
              <TextField source="category" />
            </ReferenceField>
            <TextField source="status" label="Status" sortable={false} />
            <CustomShowbutton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const ReferralPlanEdit = () => (
  <Edit
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
      </TopToolbar>
    }
  >
    <SimpleForm toolbar={<CustomSaveButton />}>
      <NumberInput
        label="Antal berättigade möten"
        source="numberOfAppointments"
        validate={required()}
      />

      <NumberInput
        label="kostnad per timme"
        source="hourlyRate"
        format={(v) => String(v / 100)}
        parse={(v) => Number(v * 100)}
        validate={required()}
      />

      <DateInput
        label="Första samtal måste genomföras senast"
        source="firstAppointmentExpiryDate"
        validate={required()}
      />

      <DateInput
        label="Remiss slutar vara giltigt från och med"
        source="expiryDate"
      />

      <ReferenceInput
        source="treatmentCategoryId"
        reference="treatment-categories"
      >
        <AutocompleteInput
          label="Behandlingsområde"
          optionText="category"
          filterToQuery={(searchText) => ({ _search: `${searchText}` })}
        />
      </ReferenceInput>

      <TextInput
        label="Remittent"
        source="referralOrigin"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
)
