import { AxiosInstance } from 'axios'

export type CancelResponse = {
  messageCode: string
}

export const cancelAuthentication = (
  client: AxiosInstance,
  orderRef: string
) => {
  return client.post<CancelResponse>('/v2/authentication/bankid/cancel', {
    orderRef,
  })
}
