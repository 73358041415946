import React from 'react'
import * as S from './KpiCard.style'

type KpiCardProps = {
  data: {
    count: number
    increase: number
  }
  label: string
}

export const KpiCard = (props: KpiCardProps) => {
  const getSinceLabel = () => {
    switch (props.label) {
      case 'Totalt antal användare':
        return 'Sedan förra månaden'
      case 'Nya behandlingsplaner':
        return 'Sedan förra veckan'
      case 'Nya användare':
        return 'Sedan förra veckan'
      case 'Totalt antal bokningar':
        return 'Sedan förra året'
    }
  }
  return (
    <S.CardContainer>
      <S.Label>{props.label}</S.Label>
      <S.Data>{props.data.count}</S.Data>
      <S.PercentWrapper>
        <S.ResultPercent positive={props.data.increase > 0}>
          {props.data.increase / 100}%
        </S.ResultPercent>
        <S.SinceLabel>{getSinceLabel()}</S.SinceLabel>
      </S.PercentWrapper>
    </S.CardContainer>
  )
}
