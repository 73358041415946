export const getLastHundredYears = () => {
  const currentYear = new Date().getFullYear()
  const dateArray = []

  for (let year = currentYear; year >= currentYear - 100; year--) {
    const obj = {
      label: year.toString(),
      value: new Date(year, 0, 1),
    }
    dateArray.push(obj)
  }

  return dateArray
}
