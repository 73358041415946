import React from 'react'
import CustomShowbutton from '@bonliva-admin/custom-showbutton'
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  EditButton,
  FunctionField,
  ImageField,
  List,
  ListButton,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  required,
  SearchInput,
  Show,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  TextField,
  TextInput,
  TopToolbar,
  BooleanInput,
  minLength,
  regex,
} from 'react-admin'
import CustomEditbutton from '@bonliva-admin/custom-editbutton'
import CustomSavebutton from '@bonliva-admin/custom-savebutton'
import { defaultTheme } from '@bonliva-ui/core/theme'
import CustomShowButton from '@bonliva-admin/custom-showbutton/CustomShowButton'
import { getLastHundredYears } from '@bonliva-traits/utils/getLastHundredYears'
import { ITreater } from '@bonliva-traits/api/types'
import { startOfYear } from 'date-fns'
import { validateSSN } from '../helpers/validateSSN'

const treaterFilters = [
  <SearchInput
    key="treater-filter-treater"
    source="name"
    alwaysOn
    parse={(searchText) => `like:${searchText}`}
    format={(searchText) => searchText?.replace('like:', '')}
  />,
]

export const TreaterList = () => (
  <List filters={treaterFilters} sort={{ field: 'Treater.name', order: 'ASC' }}>
    <Datagrid
      bulkActionButtons={false}
      rowClick={false}
      rowStyle={() => {
        return { cursor: 'auto' }
      }}
    >
      <TextField label="Namn" source="name" sortable={false} />
      <ReferenceField
        label="Profilbild"
        source="avatarId"
        reference="files"
        sortable={false}
      >
        <ImageField
          source="url"
          title="File not supported"
          sx={{
            '& .RaImageField-image': {
              height: '40px',
              width: '40px',
              borderRadius: '4px',
              objectFit: 'cover',
            },
          }}
        />
      </ReferenceField>
      <TextField label="E-mail" source="email" sortable={false} />

      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <CustomEditbutton />
        <CustomShowbutton />
      </div>
    </Datagrid>
  </List>
)

export const TreaterShow = () => (
  <Show
    actions={
      <TopToolbar>
        <ListButton label="Gå tillbaka" icon={<></>} />
        <EditButton />
      </TopToolbar>
    }
  >
    <TabbedShowLayout>
      <Tab
        label="Information"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField label="Namn" source="name" />
        <TextField label="Personnummer" source="personalNumber" />
        <TextField label="Titel" source="title" />
        <FunctionField
          label="Legitimerad sedan"
          source="licensedAt"
          render={(value: ITreater) => {
            if (!value.licensedAt) return ''
            const date = new Date(value.licensedAt)
            return date.getFullYear()
          }}
        ></FunctionField>

        <TextField label="E-mail" source="email" />
        <TextField label="Id" source="id" />
        <TextField label="Biografi" source="bio" />
        <FunctionField
          source="hourlyRate"
          render={(val: { hourlyRate: number }) => `${val.hourlyRate / 100} kr`}
        />
      </Tab>
      <Tab
        label="Bokningar"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="bookings"
          target="treaterId"
          label=""
          sort={{ field: 'Booking.startDate', order: 'DESC' }}
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <DateField
              source="startDate"
              sortBy={'Booking.startDate'}
              label="Datum"
            />
            <ReferenceField
              sortBy={'Booking.patientId'}
              source="patientId"
              reference="patients"
              label="Patient"
            >
              <TextField source="name" />
            </ReferenceField>

            <ReferenceField
              source="treatmentId"
              sortBy={'Booking.treatmentId'}
              reference="treatment-categories"
              label="Behandlingsområde"
            >
              <TextField source="category" />
            </ReferenceField>
            <TextField
              source="status"
              label="Status"
              sortBy={'Booking.status'}
            />
            <CustomShowbutton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab
        label="Remisser"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="referral-plans"
          target="treaterId"
          label=""
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <ReferenceField
              label="Patient"
              reference="patients"
              source="patientId"
              sortable={false}
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField
              label="Berättigade möten totalt"
              source="numberOfAppointments"
            />
            <DateField
              label="Första samtal måste genomföras senast"
              source="firstAppointmentExpiryDate"
              sortable
            />
            <DateField
              label="Remiss slutar vara giltigt från och med"
              source="expiryDate"
              sortable
            />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CustomShowButton />
            </div>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab
        label="Behandlingsplaner"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <ReferenceManyField
          reference="treatment-plans"
          target="treaterId"
          label=""
        >
          <Datagrid
            bulkActionButtons={false}
            rowClick={false}
            rowStyle={() => {
              return { cursor: 'auto' }
            }}
          >
            <ReferenceField
              label="Patient"
              reference="patients"
              source="patientId"
              sortable={false}
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="Psykolog"
              reference="treaters"
              source="treaterId"
              sortable={false}
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <TextField label="Status" source="status" />
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <CustomShowbutton />
            </div>
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab
        label="Avancerad"
        sx={{
          color: defaultTheme.colors.admin.darkGray,
        }}
      >
        <TextField source="id" />
        <TextField source="personalNumber" />
        <TextField source="type" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
        <TextField source="termsAndConditionsAccepted" />
        <TextField source="privacyPolicyAccepted" />
        <TextField source="emailPreferences" />
        <TextField source="createdAt" />
        <TextField source="webdocId" />
        <TextField source="bio" />
        <TextField source="slug" />
        <TextField source="title" />
        <TextField source="languages" />
        <TextField source="hourlyRate" />
        <TextField source="specialistCompetences" />
        <TextField source="treatmentCategories" />
        <TextField source="defaultWorkingTimes" />
        <TextField source="deviatingWorkingTimes" />
        <TextField source="avatar" />
        <TextField source="avatarId" />
        <TextField source="presentation" />
        <TextField source="presentationId" />
        <TextField source="licensedAt" />
        <TextField source="bookings" />
        <TextField source="careProviderId" />
        <TextField source="careProvider" />
        <TextField source="bookKeepingProject" />
        <TextField source="physicalLocationId" />
        <TextField source="physicalLocation" />
        <TextField source="acceptDigitalMeetings" />
        <TextField source="acceptPhysicalMeetings" />
        <TextField source="inactivatedAt" />
        <TextField source="completedOnboardingAt" />
        <TextField source="stripeProductId" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export const TreaterCreate = () => {
  return (
    <Create
      actions={
        <TopToolbar>
          <ListButton label="Gå tillbaka" icon={<></>} />
        </TopToolbar>
      }
    >
      <SimpleForm toolbar={<CustomSavebutton />}>
        <TextInput
          label="Personnummer"
          source="personalNumber"
          validate={[
            required('Du måste fylla i personnummer'),
            minLength(12, 'Personnumret måste vara 12 siffror'),
            regex(/^[0-9]*$/, 'Personnumret får bara innehålla siffror'),
            validateSSN,
          ]}
        />
        <TextInput
          label="Namn"
          source="name"
          validate={[required('Du måste välja ett namn')]}
        />
        <TextInput
          label="E-Mail"
          source="email"
          validate={[required('Du måste välja en e-post')]}
        />
        <NumberInput
          label="kostnad per timme"
          source="hourlyRate"
          format={(v) => String(v / 100)}
          parse={(v) => Number(v * 100)}
          validate={[required('Du måste ange ett pris per timme')]}
        />
        <ReferenceInput
          label="Vårdgivare"
          source="careProviderId"
          reference="care-providers"
          validate={[required()]}
        >
          <AutocompleteInput
            optionText={(o) => o.name}
            inputText={(o) => o.name}
            validate={[required('Du måste välja en vårdgivare')]}
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

export const TreaterEdit = () => {
  const yearValues = getLastHundredYears()
  const formattedValues = yearValues.map((year) => {
    return {
      id: startOfYear(year.value).toISOString(),
      name: year.label,
    }
  })

  return (
    <Edit
      transform={(data) => {
        delete data.rating

        return {
          ...data,
          slug: data.slug || undefined,
          workplace: data.workplace || undefined,
          experience: data.experience || null,
          inactivatedAt: data.inactivatedAt ? new Date() : null,
          physicalLocationId: data.physicalLocationId || null,
          licensedAt: data.licensedAt || null,
          webdocId: data.webdocId || '',
        }
      }}
      actions={
        <TopToolbar>
          <ListButton label="Gå tillbaka" icon={<></>} />
        </TopToolbar>
      }
    >
      <SimpleForm toolbar={<CustomSavebutton />}>
        <ReferenceInput label="Profilbild" source="avatarId" reference="files">
          <AutocompleteInput
            label="Profilbild"
            optionText={(o) => (
              <img
                height="40px"
                width="40px"
                src={o.url}
                alt="File not supported"
              />
            )}
            inputText={(o) => o.name}
          />
        </ReferenceInput>
        <ReferenceInput
          label="Presentation"
          source="presentationId"
          reference="files"
        >
          <AutocompleteInput
            optionText={(o) => o.name}
            inputText={(o) => o.name}
          />
        </ReferenceInput>
        <TextInput
          label="Personnummer"
          source="personalNumber"
          validate={[
            required('Du måste fylla i personnummer'),
            minLength(12, 'Personnumret måste vara 12 siffror'),
            regex(/^[0-9]*$/, 'Personnumret får bara innehålla siffror'),
            validateSSN,
          ]}
          disabled={true}
        />
        <TextInput label="Namn" source="name" validate={[required()]} />
        <TextInput label="E-Mail" source="email" />
        <TextInput label="Biografi" source="bio" />
        <TextInput label="Titel" source="title" />
        <AutocompleteInput
          source="licensedAt"
          label="Legitimerad sedan"
          choices={formattedValues}
        />

        <NumberInput
          label="kostnad per timme"
          source="hourlyRate"
          format={(v) => String(v / 100)}
          parse={(v) => Number(v * 100)}
        />
        <ReferenceInput
          label="Vårdgivare"
          source="careProviderId"
          reference="care-providers"
          validate={[required()]}
        >
          <AutocompleteInput
            optionText={(o) => o.name}
            inputText={(o) => o.name}
          />
        </ReferenceInput>
        <BooleanInput label="Inaktivera" source="inactivatedAt" />
      </SimpleForm>
    </Edit>
  )
}
