// Return array of unique objects
export const unique =
  <T>(...arrays: (T | T[])[]) =>
  (key: keyof T) =>
    [
      ...new Map(
        arrays
          .map((arr) => (Array.isArray(arr) ? [...arr] : [arr]))
          .flat()
          .map((item) => [item[key], item])
      ).values(),
    ]
