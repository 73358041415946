import React from 'react'
import * as S from './Spinner.style'

type Props = {
  width?: number
  height?: number
  color?: string
}

const Spinner: React.FC<Props> = (props) => {
  return (
    <S.Spinner
      src="Spinner"
      color={props.color || '#000'}
      width={props.width || 24}
      height={props.height || 24}
    />
  )
}
export default Spinner
