import styled from 'styled-components'

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ModalContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 800px;
  background-color: white;
  display: flex;
  justify-content: center;
  border-radius: 4px;
`

export const ContentWrapper = styled.div`
  width: 600px;
  padding: 24px;
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  align-items: center;
`

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
`

export const ModalTitle = styled.span`
  margin: 20px 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`

export const OpenBankIDButton = styled.button`
  width: 100%;
  padding: 12px 6px;
  margin-bottom: 12px;
  cursor: pointer;
  background-color: #ff867b;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
`

export const ThisDeviceButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #ff867b;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
`
