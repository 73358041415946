import { createStore, RootCreatorTypes } from '@bonliva-core/store'
import { Action, State } from './types'

const initialState: State = {
  hasLoaded: false,
  isLoading: false,
  hasError: undefined,
  data: [],
}

const store = createStore<State, Action>({
  name: 'health-curve',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case RootCreatorTypes.UNLOAD:
        return initialState

      case RootCreatorTypes.PENDING:
        return {
          ...state,
          isLoading: true,
          hasError: undefined,
        }

      case RootCreatorTypes.SUCCESS:
        return {
          ...state,
          hasLoaded: true,
          isLoading: false,
          data: action.payload,
        }

      case RootCreatorTypes.FAIL:
        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          hasError: action.payload,
        }

      default:
        return state
    }
  },
})

export default store
