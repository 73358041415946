import { createStore, RootCreatorTypes } from '@bonliva-core/store'
import { Action, State, Types } from './types'
import { unique } from '@bonliva-traits/utils/'

const initialState: State = {
  hasLoaded: false,
  isLoading: false,
  page: 0,
  hasMore: true,
  count: 0,
  newCount: 0,
  hasError: undefined,
  data: [],
}

const store = createStore<State, Action>({
  name: 'assignments',
  reducer: (state = initialState, action) => {
    switch (action.type) {
      case RootCreatorTypes.UNLOAD:
        return initialState

      case RootCreatorTypes.REFRESH:
        return {
          ...state,
          isLoading: true,
          hasMore: true,
          hasError: undefined,
          page: 0,
        }

      case RootCreatorTypes.PENDING:
        return {
          ...state,
          isLoading: true,
          hasError: undefined,
        }

      case RootCreatorTypes.SUCCESS:
        return {
          ...state,
          hasLoaded: true,
          isLoading: false,
          page: state.hasMore ? state.page + 1 : state.page,
          data:
            state.page === 0
              ? action.payload
              : unique(state.data, action.payload)('id'),
        }

      case Types.SET_ASSIGNMENT:
        return {
          ...state,
          data: state.data.map((item) =>
            item.id === action.payload.id ? action.payload : item
          ),
        }

      case Types.SET_NEW_ASSIGNMENTS_COUNT:
        return {
          ...state,
          newCount: action.payload,
        }

      case RootCreatorTypes.HAS_MORE:
        return {
          ...state,
          count: action.payload,
          hasMore: action.payload - state.data.length > 0,
        }

      case RootCreatorTypes.FAIL:
        return {
          ...state,
          isLoading: false,
          hasLoaded: true,
          hasError: action.payload,
        }

      default:
        return state
    }
  },
})

export default store
