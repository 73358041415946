import { AnyAction, Reducer, Middleware } from 'redux'
import { createReactReduxStoreWrapper } from '@bonliva-core/react-redux-store-wrapper'
import { createReduxStore } from './createReduxStore'

export * from './types'
export * from './rootCreatorActions'

export function createStore<State, Action extends AnyAction>({
  name,
  middleware = [],
  reducer,
}: {
  name: string
  middleware?: Middleware[]
  reducer: Reducer<State, Action>
}) {
  const store = createReduxStore({
    name,
    middleware,
    reducer,
  })

  const boundStore = createReactReduxStoreWrapper<State, Action, typeof store>({
    name,
    store,
  })

  return boundStore
}
